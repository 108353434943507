import {createSlice} from "@reduxjs/toolkit";

const initialAuthState ={
  isLoggedIn: false,
  user: undefined
};


const authSlice = createSlice({
  name:'auth',
  initialState: initialAuthState,
  reducers:{
    setLoginState(state, action){
      state.isLoggedIn = action.payload.isLoggedIn;
      state.user = action.payload.user;
    }
  }

});

export const authBaseActions = authSlice.actions;
export default authSlice.reducer;