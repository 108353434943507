import React from 'react';
import SecurityTable from "./Components/SecurityTable";
import PropTypes from "prop-types";

const rows = [
  { topic:"IDS/IPS", description:"Do you have any? Is it integrated? Is it being monitored"},
  { topic:"Internet Proxy", description:"Do you have any? Is it integrated? Is it being monitored"},
  { topic:"PAM (Privileged Access Management)", description:"do you have a centralized login platform to manage access to privilege assets to reduce the risk of password sharing? Is it integrated? It is being monitored internally or externally? Evidence (eg. CyberARK, AD)"},
  // { topic:"Password Manager", description:"Secure password keeping platform. What app do you use? It is being monitored externally or internally ? Evidence (eg. Cyberark enterprise password vault, 1password)"},
  { topic:"VPN (Virtual Private Network)", description:"Do you have any? Is it integrated? Is it being monitored? (eg. Checkpoint, cisco)"},
  { topic:"NAC (Network Access Control)", description:"Do you have any? Is it integrated anywhere to be monitored? Is it being monitored internally or externally ? Evidence ( eg. fortiNAC, cisco ISE, forescout counterACT)"},
  { topic:"DAM (Database Activity Monitoring)", description:"Do you have any database? Do you have any? Is it integrated anywhere to be monitored? Is it being monitored internally or externally ? Evidence (imperva secure sphere, solarwinds)"},
  { topic:"DLP (Data Loss Prevention )", description:"Do you have any? Is it integrated anywhere to be monitored? Is it being monitored internally or externally ? Evidence"},
  { topic:"2FA (Multi-factor authentication) Enterprise Solutions", description:"Do you have any? Is it integrated anywhere to be monitored? Is it being monitored internally or externally ? Evidence ( eg. openOTP, RSA secureID)"},
  { topic:"Deception Technology / Honeypots", description:"The presence of a deception technology will assist in having visibility over rogue users and network. ( rapid7, TrapX)"},
  { topic:"WAF (Web Application Firewall)", description:"Do you have any app hosted in environment, do you have a WAF? Is it integrated? Is it being monitored internally or externally? Evidence ( eg. Imperva, fortinet fortiweb)"},
  { topic:"DDOS ( Distribution Denial of Service)", description:"Do you have dedicated solution for DDOS? Which do you use? It is being monitored externally or internally ? Evidence (eg. DDOS-guard, radware defense pro)"},
  { topic:"WiFi Management", description:"Do you have a centralized platform for managing your wifi connections and users connecting? Vendor? It is being monitored externally or internally ? Evidence"},
  { topic:"Mobile Management", description:"Do you have Mobile device manager? Vendor? It is being monitored externally or internally ? Evidence (eg. microsoft MDM)"},
  { topic:"Asset Management", description:"How do you manage your asset inventory? Do you have a software or just an excel file? When last was it updated?  (eg. Asset explorer, spiceworks IT asset management)"},
  { topic:"Domain controller", description:"How many domain controllers in your environment? Ask for the ips and cross that they are integrated in the SIEM."},
  { topic:"Removable Media/ Storage Policy", description:"is it blocked by group policy?"},
  { topic:"Email Protection", description:"Do you have any email protection? Is it being monitored?"}
]

const SecurityReadinessSection = props => {
  return (
    <>
      <SecurityTable type={"Readiness"} rows={props.rows} reportId={props.reportId}/>
    </>
  );
};

SecurityReadinessSection.propTypes = {
  rows: PropTypes.array,
};
SecurityReadinessSection.defaultProps = {
  rows: rows,
};

export default SecurityReadinessSection;
