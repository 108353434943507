/**
 * Created by Constantine Kuks on 18/05/2021.
 */

import React from 'react';
import classes from "./Navbar.module.css";
import { NavLink } from 'react-router-dom';

const Logo = (props) => {
  return (
    <NavLink to='/' className={classes.logo}>
      <img src={'/assets/Logo.svg'} alt="Logo"/>
    </NavLink>
  );
};
export default Logo;
