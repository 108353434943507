/**
 * Created by ConsSoft on 09/08/2022
 */
import React, {useState} from 'react';
import PropTypes from "prop-types";
import moment from "moment/moment";
import style from "./ReportDateDisplay.module.scss";
import classNames from "classnames";
import {faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {USER_TYPE_CUSTOMER} from "../../common/userTypes";
import Scrollbar from "react-perfect-scrollbar";

export const ReportDateDisplay = (props) => {

  const [dropDownOpen, setDropDownOpen] = useState(false)
  let reportList = [...props.reportList].sort((a,b) => a.dateRange.localeCompare(b.dateRange));

  const onClickDateHandler = () => {
    setDropDownOpen(!dropDownOpen);
  }
  if ( props.userType !== USER_TYPE_CUSTOMER){
    reportList = reportList.sort((a,b) => a.customer.customerCode.localeCompare(b.customer.customerCode));
  }

  return (
    <>
      <span className={props.style} onClick={onClickDateHandler}>{moment(props.selectedTimeframe).format( "MMMM yyyy" )}&nbsp;
        <FontAwesomeIcon className={classNames('expander')} icon={(dropDownOpen ? faCaretUp : faCaretDown)}/>
      </span>
      {dropDownOpen &&
        <>
          <Scrollbar className={style.dropdown} options={{wheelSpeed: 0.2}} >

              { reportList.map(report => (
                <a href={`/report/${report.uuid}`}>
                  {props.userType !== USER_TYPE_CUSTOMER ? `${report.customer.customerCode} - ` : ''}
                  {report.dateRange}</a>
              ))}

          </Scrollbar>
          <div className={style.backdrop} onClick={onClickDateHandler}></div>
        </>

      }
    </>
  );
};

ReportDateDisplay.propTypes = {
  selectedTimeframe: PropTypes.string,
  style: PropTypes.string,
  reportList: PropTypes.array,
  userType: PropTypes.string,
};

export default ReportDateDisplay;