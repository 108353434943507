import './App.css';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withLocalize} from 'react-localize-redux';
import Layout from "./layouts/Layout";
import {withRouter, Switch, Route} from "react-router-dom";
import "./translations/localize";
import "./translations/localize.en";
import authActions from "./store/actions/authActions";
import Auth from "./components/common/Auth";
import AppRoutes from "./routes";
import * as userRole from "./common/userRoles";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'underscore';
import uiActions from "./store/actions/uiActions";
import Report from "./components/reportView/Report";
import {Redirect} from "react-router";

// styles
import './styles/_variables.scss'
import './styles/_common.scss'
import reportActions from "./store/actions/reportActions";

export class App extends Component {
  constructor(props) {
    super(props);

    if (props.initialize) {
      props.initialize(window.localize);

      window.localize.languages.forEach(language => {
        props.addTranslationForLanguage(window.localize[language.code], language.code);
      });
    }

  }

  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(authActions.checkLogin());
    dispatch(reportActions.getReportsList());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {dispatch} = this.props;
    if (prevProps.ui.error !== this.props.ui.error && !_.isEmpty(this.props.ui.error)) {
      toast.error(this.props.ui.error);
      dispatch(uiActions.setError({message: undefined}));
    }
    if (prevProps.ui.success !== this.props.ui.success && !_.isEmpty(this.props.ui.success)) {
      toast.success(this.props.ui.success);
      dispatch(uiActions.setSuccess({message: undefined}));
    }
  }

  render() {

    const currentUserType = this.props.auth.isLoggedIn ? this.props.auth.user.userType || 'customer' : 'customer';
    const currentUserRole = this.props.auth.isLoggedIn ? this.props.auth.user.role || userRole.ROLE_CLIENT : userRole.ROLE_CLIENT;

    return (
      <div className="App">
        <ToastContainer/>
        <Switch>
          <Route path='/report/:id' render={(props) => {
            if (this.props.auth.isLoggedIn)
              return <Report id={props.match.params.id} userType={currentUserType}/>;
            else
              return <Auth/>;
          }}/>

          <Route path='/report'><Redirect to="/reports"/></Route>
          <Route path='/' render={() =>
            <Layout userRole={currentUserRole}>
              <Route path='/'
                     render={() => this.props.auth.isLoggedIn ?
                       <AppRoutes userType={currentUserType} userRole={currentUserRole}/> : <Auth/>}/>
            </Layout>
          }/>
        </Switch>

      </div>
    );
  }
}

const mapStoreToProps = (store) => ({
  auth:  store.auth,
  store: store,
  ui:    store.ui
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}


export default withRouter(connect(mapStoreToProps, mapDispatchToProps)(withLocalize(App)));
