import React from 'react';
import {Route} from "react-router";

const EmployeeRoutes = props => {
  return (
    <>
      <Route path="/profile">
      </Route>
    </>
  );
};

export default EmployeeRoutes;
