import React, { useState} from 'react';
import PropTypes from 'prop-types';
import style from './ReportHeader.module.scss';
import HeaderSummary from "./dashboard/headerSummary/HeaderSummary";
import HeaderActions from "./dashboard/HeaderActions/HeaderActions";
import classnames from "classnames";
import StatisticsPane from "./dashboard/Statistics/StatisticsPane";
import {withRouter} from "react-router-dom";
import ReportDateDisplay from "./ReportDateDisplay";

const ReportHeader = props => {

  const [isStatisticsOpening, setIsStatisticsOpening] = useState(false);

  const handleToggleStatistics = () =>{
    setIsStatisticsOpening(!isStatisticsOpening);
  }

  return (
    <div className={classnames(style['header--main'], props.className)}>
      <div className={style['header--content']}>

        <div className={style.image}>
          <img src={props.logo} alt="Customer logo"/>
        </div>

        <div className={style.title}>
          <h3>CISO REPORT</h3>
          <ReportDateDisplay selectedTimeframe={props.timeframe} style={style.subtitle} reportList={props.reportList} userType={props.userType}/>
        </div>

        <HeaderSummary {...props.unresolved} ttdr={props.ttdr}/>
        <HeaderActions reportId={props.reportId} userType={props.userType}/>

      </div>
      {!isStatisticsOpening && <div className={style.handle} onClick={handleToggleStatistics}>
        <div className={style["handle__line"]}> </div>
      </div>}

      <StatisticsPane isOpen={isStatisticsOpening} statistics={props.statistics}/>
      {isStatisticsOpening && <div className={style.secHandle} onClick={handleToggleStatistics}>
        <div className={style["handle__line"]}> </div>
      </div>}
    </div>
  );
};

ReportHeader.propTypes = {
  reportId: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  timeframe: PropTypes.string.isRequired,
  unresolved: PropTypes.shape({
      critical: PropTypes.number.isRequired,
      medium: PropTypes.number.isRequired,
      low: PropTypes.number.isRequired,
  }).isRequired,
  ttdr: PropTypes.number.isRequired,
  statistics: PropTypes.object,
  reportList: PropTypes.array,
  userType: PropTypes.string,
};

export default withRouter(ReportHeader);
