import React from 'react';
import PropTypes from 'prop-types';
import './Recommendation.scss';
import classNames from "classnames";
import {Translate, withLocalize} from "react-localize-redux";
import Scrollbar from "react-perfect-scrollbar";

const Recommendation = props => {
  return (
    <div className={classNames('recommendation')}>
      <div className="title">Top recommendation by impact</div>

      <div className="recommendation--box">
        <Scrollbar className={classNames("recommendation--box--scrollbar",{open:props.isOpen})} options={{wheelSpeed: 0.2}}>
          <div className="recommendation--box--header">
            <img src="/assets/reports/ic_16px_insight.svg" alt="Insights icon for recommendation"/>
            <h2 className="title"><Translate id="reports.recommendation"/></h2>
          </div>
          <p className="recommendation--box--text">{props.recommendationText}</p>
        </Scrollbar>
      </div>

    </div>
  );
};

Recommendation.propTypes = {
  recommendationText: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
};

export default withLocalize(Recommendation);