/**
 * Created by Constantine Kuks on 18/05/2021.
 */

import React from 'react';
import {withLocalize} from 'react-localize-redux';
import classes from './Navbar.module.css';
import classNames from 'classnames';
import Logo from "./Logo";
import NavItems from "./NavItems";
import Auth from "./Auth";
import {withRouter} from "react-router-dom";
import {useSelector} from "react-redux";
import userRoles from '../../common/userRoles';
import PropTypes from "prop-types";

const Navbar = (props) => {
  const authState = useSelector(state=> state.auth);

  return (
    <div className={classNames(classes.navbar, 'nav-background')}>
      <div className={classNames('content', classes.content)}>
        <Logo/>
        <nav className={classNames(classes["nav-items"], 'desktopOnly')}>
          <NavItems userRole={props.userRole}/>
        </nav>
        <div className={classNames(classes.auth)}>
          <Auth isAuthenticated={authState.isLoggedIn}/>
        </div>
      </div>
    </div>
  );
};

Navbar.propTypes = {
  userRole: PropTypes.oneOf(userRoles)
}

export default withLocalize(withRouter(Navbar));

