import React, {useEffect} from 'react';
import ReactTable from 'react-table-v6';
import PropTypes from 'prop-types';
import styles from './ReportList.module.scss';
import {useHistory} from 'react-router';
import 'react-table-v6/react-table.css';
import {useDispatch, useSelector} from 'react-redux';
import userTypes, {USER_TYPE_COMPANY} from '../../common/userTypes';
import userRoles from '../../common/userRoles';
import reportActions from '../../store/actions/reportActions';
import './ReportListReactTableFix.scss';
import classnames from 'classnames';
import Scrollbar from 'react-perfect-scrollbar';
import moment from 'moment';


const ReportList = (props) => {

  const reportState = useSelector(state => state.report);
  const authState = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(reportActions.getReportsList());
    // eslint-disable-next-line
  }, []);


  const handleAddReport = () => {
    history.push('/reports/create');
  }

  const handleImportReport = (e) => {
    history.push('/reports/import');
  }

  const handleEdit = (id) => {
    history.push('/reports/edit/' + id);
  }

  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this report?')) {
      dispatch(reportActions.deleteReport(id));
    }
  }

  const handlePublished = (id, newValue) => {
    if (window.confirm(`Are you sure you want to ${newValue ? 'publish' : 'un-publish'} this report?`)) {
      dispatch(reportActions.setReportPublished(id, newValue));
    }
  }

  const CustomTbodyComponent = (props) => (
    <div {...props} className={'rt-tbody ' + (props.className || '')}>
      <Scrollbar>{props.children}</Scrollbar>
    </div>
  );

  const columns = [
    // {Header: 'ID', accessor: 'uuid', headerClassName: styles.headers, className: styles.rows, width: 200},
    {
      Header: 'Date', accessor: 'dateRange', headerClassName: styles.headers, className: styles.rows,
      Cell:   ({row}) => {
        return (<span>{moment(row.dateRange).format('MMMM yyyy')}</span>
        )
      },
      width:  200
    },
    {Header: 'Created at', accessor: 'createdAt', headerClassName: styles.headers, className: styles.rows},
  ];

  if (authState.user.userType === USER_TYPE_COMPANY) {
    columns.push({
      Header:          'Customer',
      accessor:        'customer.name',
      headerClassName: styles.headers,
      className:       styles.rows,
      width:           200
    })

    columns.push({
      Header:          'Published',
      accessor:        'isPublished',
      headerClassName: styles.headers,
      className:       styles.center,
      width:           120,
      Cell:            ({row}) => {
        const isPublished = row.isPublished;
        return (
          <React.Fragment>
            <button
              className={classnames(styles.published, {[styles.green]: isPublished})}
              value={row.uuid}
              onClick={() => handlePublished(row._original.uuid, !isPublished)}>
              {isPublished ? 'Published' : 'Not published'}
            </button>
          </React.Fragment>
        );
        // return (<span className={classnames(styles.published,{[styles.green]: row.isPublished})}>{"" + row.isPublished}</span>)

      },
    })

    columns.push({
      Header:          '',
      accessor:        '',
      headerClassName: styles.headers,
      className:       styles.center,
      width:           160,
      Cell:            ({row}) => {
        return (
          <React.Fragment>
            <button value={row.uuid} onClick={() => handleEdit(row._original.uuid)}>Edit</button>
            &nbsp;
            <button value={row.uuid} onClick={() => handleDelete(row._original.uuid)}>Delete</button>
          </React.Fragment>
        )
      }
    })
  }

  const handleSelectReport = (id) => {
    history.push('/report/' + id);
  }

  return (
    <div className={styles.wrapper}>
      <div>
        {props.userType === USER_TYPE_COMPANY && <button onClick={handleAddReport}>+ Add report</button>}
        {props.userType === USER_TYPE_COMPANY && <button onClick={handleImportReport}>+ Import report</button>}
      </div>
      <ReactTable
        className={classnames(styles.table, '-striped -highlight table')}
        sortable={true}
        data={reportState.list}
        columns={columns}
        style={{
          height: '90%' // This will force the table body to overflow and scroll, since there is not enough room
        }}
        TbodyComponent={CustomTbodyComponent}
        getTrProps={(state, rowInfo) => {

          return {
            onClick: () => {
              if (rowInfo && rowInfo.original) {
                handleSelectReport(rowInfo.original.uuid);
              }
            }
          }
        }}
        getTdProps={(state, rowInfo, column) => {
          return {
            onClick: (e, handleOriginal) => {
              if (handleOriginal && (
                column.id === 'uuid' ||
                column.id === 'dateRange' || column.id === 'customer.name' ||
                column.id === 'createdAt')) {
                handleOriginal()
              } else {
                e.stopPropagation();
              }
            }
          }
        }}
      />
    </div>
  )
};

ReportList.propTypes = {
  reports:  PropTypes.array.isRequired,
  userType: PropTypes.oneOf(userTypes),
  userRole: PropTypes.oneOf(userRoles),
}

export default ReportList;
