import React from 'react';
import PropTypes from 'prop-types';
import DropdownInput from "../../../common/DropdownInput";

const options = [
  {
    name:  "Critical",
    value: 3
  }, {
    name:  "High",
    value: 2
  }, {
    name:  "Medium",
    value: 1
  }, {
    name:  "Low",
    value: 0
  },
];

const RiskDropdown = props => {
  return (
    <DropdownInput value={props.value} onChange={props.onChange} options={options} hasLabel={false}
                   name={props.name.toLocaleLowerCase() || "risk"} isRequired={false}/>
  );
};

RiskDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  value:    PropTypes.number.isRequired,
  name: PropTypes.string,
};

export default RiskDropdown;
