import React from 'react';
import PropTypes from 'prop-types';

const SimpleTextInput = props => {

  const handleChange = (event) => {
    props.onChange && props.onChange(event.target.value);
  }

  return (
    <textarea style={{margin: 0}} placeholder={props.placeholder} onChange={handleChange} value={props.value}
              name={props.name} required={props.required}/>

  );
};

SimpleTextInput.propTypes = {
  name:        PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange:    PropTypes.func.isRequired,
  value:       PropTypes.string.isRequired,
  required:    PropTypes.bool
};

SimpleTextInput.defaultProps = {
  placeholder: undefined
};

export default SimpleTextInput;
