import React from 'react';
import {RadialBarChart, RadialBar, PolarAngleAxis} from "recharts";
import PropTypes from 'prop-types';
import './Chart.scss';

const style = {
  top:        0,
  left:       50,
  lineHeight: "24px"
};

export const Chart = props => {

  const data = [
    {
      name: "operational",
      uv:   Math.round(props.operational),
      fill: "#5AB7BD"
    },
    {
      name: "readiness",
      uv:   Math.round(props.readiness),
      fill: "#349BC8"
    },
    {
      name: "visibility",
      uv:   Math.round(props.visibility),
      fill: "#4173BF"
    },
    {
      name: "empty",
      uv:   0,
      fill: "#4173BF"
    },
    {
      name: "score",
      uv:   Math.round(props.score),
      fill: "#092247"
    }

  ];

  let customTick = (props) => {
    let {x, y, payload} = props;
    if (payload.value === 100) {
      return undefined;
    }

    return <g className="recharts-layer recharts-polar-angle-axis-tick">
      <text type="number" cx="200" cy="200" orientation="outer" radius="20" stroke="none" x={x} y={y}
            className="recharts-text recharts-polar-angle-axis-tick-value" textAnchor="middle">
        <tspan x={x} dy="0em">{payload.value}</tspan>
      </text>
    </g>;
  }

  const CustomLabel = props => {
    const {endAngle, outerRadius, innerRadius} = props.viewBox;
    const { name} = props;

    const radius = (innerRadius + outerRadius) / 2;
    const x = Math.cos(Math.PI / 180 * endAngle) * radius;
    const y = Math.sin(Math.PI / 180 * endAngle) * radius;
    let newX = x + 200 - 18;
    let newY = -y + 200 - 18;

    if (name === "empty") {
      return null;
    }
    if (name === "score") {
      newX -= 11;
      newY -= 11.5;
    }


    return (
      <foreignObject className="custom-wrapper" x={newX} y={newY}>
        <div xmlns="http://www.w3.org/1999/xhtml" className="custom-label">
          <img src={`/assets/reports/chart/circle_${props.name}.png`} alt={`Icon for ${props.name}`}/>
        </div>
      </foreignObject>
    );
  };

  const dashedLines = <foreignObject className="dashed-lines" x={200 - 143} y={200 - 143}>
    <div xmlns="http://www.w3.org/1999/xhtml" className="custom-label">
      <img src={`/assets/reports/chart/dash_lines.png`} alt="Graph dashed lines"/>
    </div>
  </foreignObject>;

  const bigWhiteCircle = <foreignObject className="big-white-circle" x={-7.5} y={-7.5}>
    <div xmlns="http://www.w3.org/1999/xhtml" className="custom-label">
      <img src={`/assets/reports/chart/big_white_circle.png`} alt="Graph background"/>
    </div>
  </foreignObject>;

  const innerCircle = <foreignObject className="inner-circle" x={101} y={101.5}>
    <div xmlns="http://www.w3.org/1999/xhtml" className="custom-label">
      <img src={`/assets/reports/chart/inner_circle.png`} alt="Inner graph circle"/>
    </div>
  </foreignObject>;

  const shield = <foreignObject className="shield" x={200 - 148 / 2} y={200 - 176 / 2}>
    <div xmlns="http://www.w3.org/1999/xhtml" className="custom-label">
      <img src={`/assets/reports/chart/shield.png`} alt="Score icon"/>
    </div>
  </foreignObject>;

  const TotalScore = <foreignObject className="total-score" x={200 - 45} y={200 - 40}>
    <div xmlns="http://www.w3.org/1999/xhtml" className="custom-label">
      {Math.round(props.score)}
    </div>
  </foreignObject>;

  return (
    <RadialBarChart
      width={400}
      height={400}
      cx={200}
      cy={200}
      innerRadius={100}
      outerRadius={170}
      barSize={4}
      data={data}
      startAngle={90}
      endAngle={-270}
      style={style}
    >
      {bigWhiteCircle}
      {dashedLines}
      {innerCircle}
      {shield}
      {TotalScore}

      <PolarAngleAxis type="number" domain={[0, 100]} angleAxisId={0} tick={customTick} tickCount={5}/>
      <RadialBar
        minAngle={15}

        clockWise
        dataKey="uv"
        label={CustomLabel}
      />
    </RadialBarChart>
  );
};

Chart.propTypes = {
  score:       PropTypes.number.isRequired,
  operational: PropTypes.number.isRequired,
  readiness:   PropTypes.number.isRequired,
  visibility:  PropTypes.number.isRequired
};

export default Chart;
