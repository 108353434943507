import React from 'react';
import PropTypes from 'prop-types';
import SecurityTableRow from "./SecurityTableRow";
import SecurityTableRowHeader from "./SecurityTableRowHeader";
import styles from "./SecurityTable.module.scss";

const SecurityTable = props => {

  return (
    <table className={styles.table}>
      <thead>
        <SecurityTableRowHeader/>
      </thead>
      <tbody>
        {props.rows.map((row, index) => (
          <SecurityTableRow key={row.topic + index} rowIndex={index} {...row}
                            type={props.type}  reportId={props.reportId}/>
        ))}
      </tbody>
    </table>
  );
};

SecurityTable.propTypes = {
  type: PropTypes.oneOf(['Operational', 'Readiness', 'Visibility']).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({
    topic:       PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    note:        PropTypes.string,
    solution:    PropTypes.string,
    risk:        PropTypes.number,
    compliance:  PropTypes.number,
  })).isRequired,
  reportId: PropTypes.string,
};

export default SecurityTable;
