import React from 'react';
import SecurityTable from "./Components/SecurityTable";
import PropTypes from "prop-types";

const rows = [
  {
    topic:       "SIEM",
    description: "What SIEM is being used ?This is measured by the number of assets integrated and actively being monitored from the list of assets under SECURITY READINESS"
  }, {
    topic:       "SOC Monitoring and Alerting",
    description: "Are you subscribed to an MSSP, what is the name ? or do run your own SOC - for none cyberSOC clients, is it 24/7 or work weekday only?"
  }, {
    topic:       "EDR (Endpoint Detection Response)",
    description: "Do you have an EDR tool? Which vendor?, is it actively being monitored, what percentage of your total asset is bring monitored. (how many estimated endpoint, how many licences bought, how many installed?)"
  }, {
    topic:       "NBA (Network Behaviour Analysis)",
    description: "A tool that detects anormalous behaviour on the network? Network flow analysis? What is the tool? ( eg, cisco stealthwatch, riverbed steelcentral netprofiler)"
  }, {
    topic:       "Incident Response SLA",
    description: "Are you subscribe to IR, do you have an IR plan? what is the level of completion for their documentation? Anything else pending? Show proof"
  }
]

const SecurityOperationSection = props => {
  return (
    <>
      <SecurityTable type={"Operational"} rows={props.rows} reportId={props.reportId}/>
    </>
  );
};

SecurityOperationSection.propTypes = {
  rows: PropTypes.array,
};

SecurityOperationSection.defaultProps = {
  rows: rows
};

export default SecurityOperationSection;
