import React from 'react';
import PropTypes from 'prop-types';
import styles from './HeaderSummary.module.scss';

const HeaderSummary = props => {

  const minutes = (props.ttdr%1*60).toFixed(0);
  const hours = Math.floor(props.ttdr/1);

  return (
    <div className={styles.wrapper}>
      <div className={styles.unresolved}>
        <div>Unresolved Alerts</div>
        <div className={styles.item}>
          <img src='/assets/reports/severities/critical.png' alt={"Critical severity icon"}/>
          <div className={styles.itemNumber}>{props.critical}</div>
          <div className={styles.itemTitle}>Critical</div>
        </div>

        <div className={styles.item}>
          <img src='/assets/reports/severities/high.png' alt={"Medium severity icon"}/>
          <div className={styles.itemNumber}>{props.high}</div>
          <div className={styles.itemTitle}>High</div>
        </div>

        <div className={styles.item}>
          <img src='/assets/reports/severities/medium.png' alt={"Medium severity icon"}/>
          <div className={styles.itemNumber}>{props.medium}</div>
          <div className={styles.itemTitle}>Medium</div>
        </div>

        <div className={styles.item}>
          <img src='/assets/reports/severities/low.png' alt={"Low severity icon"}/>
          <div className={styles.itemNumber}>{props.low}</div>
          <div className={styles.itemTitle}>Low</div>
        </div>
      </div>

      <div className={styles.ttdr}>
        <div className={styles.ttdrTitle}>MTTDR</div>
        <img src="/assets/reports/clock.png"  alt={"Clock icon"}/>
        <div className={styles.ttdrTime}>
          {hours}:{minutes}hr
        </div>
      </div>
    </div>
  );
};

HeaderSummary.propTypes = {
  critical: PropTypes.number.isRequired,
  high :    PropTypes.number.isRequired,
  medium:   PropTypes.number.isRequired,
  low:      PropTypes.number.isRequired,
  ttdr:     PropTypes.number.isRequired,
};

HeaderSummary.defaultProps = {
  critical: 0,
  high:     0,
  medium:   0,
  low:      0,
  ttdr:     {
    hours:   0,
    minutes: 0,
  },
}

export default HeaderSummary;
