import React from 'react';
import PropTypes from 'prop-types';
import {PieChart, Pie, Cell, Legend} from 'recharts';
import StatisticsSection from "./StatisticsSection";
import styles from './StatisticsInBySev.module.scss'


const COLORS = ['#5F6060', '#27AE6D', '#FFC907', '#EE8F00', '#DA2E49'];

const StatisticsInBySev = props => {

  const {info, low, medium, high, critical} = props.incidentsBySeverity;

  const data = [
    {name: 'Group A', value: info},
    {name: 'Group A', value: low},
    {name: 'Group A', value: medium},
    {name: 'Group A', value: high},
    {name: 'Group A', value: critical},
  ];

  const total = data.reduce((tot, current) => tot + current.value,0);

  return (
    <>
      <StatisticsSection title={"Total Alerts By Severity"}>
        <div className={styles.wrapper}>
          <PieChart width={260} height={260}>
            <Pie
              data={data}
              cx={122.5}
              cy={122.5}
              innerRadius={115}
              outerRadius={122.5}
              fill="#8884d8"
              paddingAngle={3}
              dataKey="value"
              startAngle={90}
              endAngle={450}
              stroke="none"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
              ))}
              <Legend/>
            </Pie>

            <foreignObject className={styles.score} x={122.5 - 35} y={122.5 - 35}>
              <div xmlns="http://www.w3.org/1999/xhtml" className="custom-label">
                <div className={styles.num}>{total}</div>
                <div className={styles.subtitle}>Alerts</div>
              </div>
            </foreignObject>

          </PieChart>

          <div className={styles.legend}>

            <div className={styles['legend__item']}>
              <img src="/assets/reports/severities/critical.png" alt="Critical severity icon"/>
              <div>
                <div className={styles.number}>{critical}</div>
                Critical
              </div>
            </div>

            <div className={styles['legend__item']}>
              <img src="/assets/reports/severities/high.png" alt="High severity icon"/>
              <div>
                <div className={styles.number}>{high}</div>
                High
              </div>
            </div>

            <div className={styles['legend__item']}>
              <img src="/assets/reports/severities/medium.png" alt="Medium severity icon"/>
              <div>
                <div className={styles.number}>{medium}</div>
                Medium
              </div>
            </div>

            <div className={styles['legend__item']}>
              <img src="/assets/reports/severities/low.png" alt="Low severity icon"/>
              <div>
                <div className={styles.number}>{low}</div>
                Low
              </div>
            </div>

            <div className={styles['legend__item']}>
              <img src="/assets/reports/severities/info.png" alt="Info severity icon"/>
              <div>
                <div className={styles.number}>{info}</div>
                Info
              </div>
            </div>

          </div>

        </div>
      </StatisticsSection>
    </>
  );
};

StatisticsInBySev.propTypes = {
  incidentsBySeverity: PropTypes.object.isRequired,
};

export default StatisticsInBySev;
