import {createSlice} from "@reduxjs/toolkit";

const initialReportState = {
  list:           [],
  isFetched:      false,
  fullReports: [],
  cardListFilter: {
    risk:   [],
    comply: []
  },
};


const reportSlice = createSlice({
  name:         'report',
  initialState: initialReportState,
  reducers:     {
    addReportToFullList(state, action) {
      state.fullReports.push(action.payload);
    },
    removeReportFromFullList(state, action) {
      state.fullReports = state.fullReports.filter(report => report.uuid !== action.payload);
    },
    updateReportInFullList(state, action) {
      state.fullReports = state.fullReports.map(report => {
        if (report.uuid === action.payload.uuid) {
          return{
            ...report,
            ...action.payload
          }
        }
        return report;
      });
    },
    setReportList(state, action) {
      state.list = action.payload;
    },
    updateReportList(state, action) {
      state.list = state.list.map(report => {
        if (report.uuid === action.payload.uuid) {
          return{
            ...report,
            ...action.payload
          }
        }
        return report;
      });
    },
    removeReportFromList(state, action) {
      state.list = state.list.filter(report => report.uuid !== action.payload);
    },
    setFullReports(state, action) {
      state.fullReports = action.payload;
    },
    setIsFetched(state, action) {
      state.isFetched = action.payload;
    },
    resetCardListFilter(state, action) {
      state.cardListFilter = {risk: [], comply: []};
    },
    addCardListFilter(state, action) {
      state.cardListFilter[action.payload.type].push(action.payload.value);
    },
    removeCardListFilter(state, action) {
      state.cardListFilter[action.payload.type] = state.cardListFilter[action.payload.type].filter(filter => filter !== action.payload.value);
    }
  }

});

export const reportsBaseActions = reportSlice.actions;
export default reportSlice.reducer;