import axios from "axios";
import {authBaseActions} from "../reducers/auth";
import {remove} from 'react-cookies';
import uiActions from "./uiActions";



export const checkLogin = () => {
  return (dispatch) => {
    axios.post(`/api/authenticate`, {}, {withCredentials: true})
      .then((response) => {
        dispatch(authBaseActions.setLoginState({isLoggedIn: true, user: response.data.user}));
      })
      .catch((err) => {
        remove('Authorization');
        dispatch(uiActions.setError({message:err.message}));
        dispatch(authBaseActions.setLoginState({isLoggedIn: false, user: undefined}));
      })
  };
};

export const login = (email, password) => {
  return (dispatch) => {
    axios.post(`/api/login`, {
        email,
        password
      }, {
        headers:         {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      })
      .then((response) => {
        dispatch(uiActions.setSuccess({message: "Logged in successfully"}));
        dispatch(authBaseActions.setLoginState({isLoggedIn: true, user: response.data.user}));
      })
      .catch((err) => {
        remove('Authorization');
        dispatch(uiActions.setError({message:err.message}));
        dispatch(authBaseActions.setLoginState({isLoggedIn: false, user: undefined}));
      })
  };

};

export const logout = () => {
  return (dispatch) => {
    axios.post(`/api/logout`, {}, {withCredentials: true})
      .then((response) => {
        dispatch(authBaseActions.setLoginState({isLoggedIn: false, user: undefined}));
        remove('Authorization');
      })
      .catch((err) => {

        dispatch(authBaseActions.setLoginState({isLoggedIn: false, user: undefined}));
      })
  };
};


const allAuthActions = {
  ...authBaseActions,
  logout,
  checkLogin,
  login
};


export default allAuthActions;
