/**
 * Created by Constantine Kuks on 18/05/2021.
 */

import React from 'react';
import {withLocalize} from 'react-localize-redux';
import NavBar from "../components/navigation/Navbar";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import userTypes from "../common/userRoles";
import styles from "./Layout.module.scss";

class Layout extends React.Component {
  state = {
    // state
  };

  static propTypes = {
    userRole: PropTypes.oneOf(userTypes).isRequired
  };

  render() {
    return (
      <div>
        <NavBar userRole={this.props.userRole}/>
        <div className={styles.content}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default withLocalize(withRouter(Layout));
