import styles from "./StatisticItem.module.scss";
import PropTypes from "prop-types";
import React from "react";

const StatisticItem = (props) => {
  return <div className={styles['item']}>
    <div className={styles.title}>{props.title}</div>
    <div><span className={styles.value}>{props.value}</span><span className={styles.subtitle}>{props.subtitle}</span></div>
  </div>
}

StatisticItem.propTypes = {
  title:    PropTypes.string,
  subtitle: PropTypes.string,
  value:    PropTypes.any
}

export default StatisticItem;
