import React, {Suspense} from 'react';
import PropTypes from 'prop-types';
import CustomerRoutes from "./CustomerRoutes";
import EmployeeRoutes from "./EmployeeRoutes";
// import AdminRoutes from "./AdminRoutes";
import userRoles, {ROLE_ADMIN} from "../common/userRoles";
import userTypes, {USER_TYPE_COMPANY, USER_TYPE_CUSTOMER} from "../common/userTypes";
import CommonRoutes from "./CommonRoutes";
import {Redirect, Route} from "react-router";
import ReportList from "../components/reports/ReportList";
import ReportsForm from "../components/reports/ReportsForm";
import ReportsImportForm from '../components/reports/ReportsImportForm';

const AdminRoutes = React.lazy(() => import('./AdminRoutes'));

const AppRoutes = props => {
  return (
    <>
      <CommonRoutes/>
      <Route path='/' exact><Redirect to={'/reports'}/></Route>
      <Route path='/reports' exact render={(routeProps) => <ReportList {...{...props, ...routeProps}}/>}/>
      {props.userType === USER_TYPE_COMPANY && <Route path='/reports/create' exact component={ReportsForm} {...props}/>}
      {props.userType === USER_TYPE_COMPANY && <Route path='/reports/edit/:id' exact render={(props) => (
        <ReportsForm reportId={props.match.params.id}/>)} {...props}/>}
      {props.userType === USER_TYPE_COMPANY && <Route path='/reports/import' exact render={(props) => (
        <ReportsImportForm/>)} {...props}/>}
      {props.userType === USER_TYPE_CUSTOMER && <CustomerRoutes/>}
      {props.userType === USER_TYPE_COMPANY && <EmployeeRoutes/>}
      {
        props.userType === USER_TYPE_COMPANY && props.userRole === ROLE_ADMIN &&
        <Route path='/admin' render={() => (
          <Suspense fallback={<p>Loading</p>}>
            <AdminRoutes/>
          </Suspense>
        )}/>
      }


    </>
  );
}


AppRoutes.propTypes = {
  userType: PropTypes.oneOf(userTypes),
  userRole: PropTypes.oneOf(userRoles),
};


export default AppRoutes;
