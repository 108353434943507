import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Card} from "./dashboard/Summary Cards/Card";

const ReportRightPane = props => {
  const {
    secVis, secReady, secOps, secOpsRecommendation, secReadyRecommendation, secVisRecommendation,
    secOpsScore, secVisScore, secReadyScore,secReadyScoreHistory,secOpsScoreHistory,secVisScoreHistory
  } = props;

  const [isOpMinimized, setIsOpMinimized] = useState(false);
  const [isReadMinimized, setIsReadMinimized] = useState(false);
  const [isVisMinimized, setIsVisMinimized] = useState(false);

  const opCardRef = React.createRef();
  const readyCardRef = React.createRef();
  const visCardRef = React.createRef();

  const handleCardOpen = (title) => {
    if (title !== "Operational") {
      opCardRef.current.closeSummary();
      setIsOpMinimized(true);
    }
    if (title !== "Readiness") {
      readyCardRef.current.closeSummary();
      setIsReadMinimized(true);
    }
    if (title !== "Visibility") {
      visCardRef.current.closeSummary();
      setIsVisMinimized(true);
    }
    if (title === "Operational") setIsOpMinimized(false);
    if (title === "Readiness") setIsReadMinimized(false);
    if (title === "Visibility") setIsVisMinimized(false);
  }
  const handleCardClose = () => {
    setIsOpMinimized(false);
    setIsReadMinimized(false);
    setIsVisMinimized(false);
  }

  return (
    <div className={props.className}>

      <Card ref={opCardRef}
            title={"Operational"}
            data={secOps}
            recommendation={secOpsRecommendation}
            onOpen={handleCardOpen}
            onClose={handleCardClose}
            isMinimized={isOpMinimized}
            score={secOpsScore}
            scoreHistory={secOpsScoreHistory}/>

      <Card ref={readyCardRef}
            title={"Readiness"}
            data={secReady}
            recommendation={secReadyRecommendation}
            onOpen={handleCardOpen}
            onClose={handleCardClose}
            isMinimized={isReadMinimized}
            score={secReadyScore}
            scoreHistory={secReadyScoreHistory}/>

      <Card ref={visCardRef}
            title={"Visibility"}
            data={secVis}
            recommendation={secVisRecommendation}
            onOpen={handleCardOpen}
            onClose={handleCardClose}
            isMinimized={isVisMinimized}
            score={secVisScore}
            scoreHistory={secVisScoreHistory}/>
    </div>
  );
};

ReportRightPane.propTypes = {
  secOps:                 PropTypes.array.isRequired,
  secReady:               PropTypes.array.isRequired,
  secVis:                 PropTypes.array.isRequired,
  secOpsScore:            PropTypes.number.isRequired,
  secReadyScore:          PropTypes.number.isRequired,
  secVisScore:            PropTypes.number.isRequired,
  secOpsScoreHistory:     PropTypes.arrayOf(PropTypes.number).isRequired,
  secReadyScoreHistory:   PropTypes.arrayOf(PropTypes.number).isRequired,
  secVisScoreHistory:     PropTypes.arrayOf(PropTypes.number).isRequired,
  secOpsRecommendation:   PropTypes.string.isRequired,
  secReadyRecommendation: PropTypes.string.isRequired,
  secVisRecommendation:   PropTypes.string.isRequired,
};

export default ReportRightPane;
