import React from 'react';
import {Route} from "react-router";

const CustomerRoutes = props => {
  return (
    <>
      <Route path="/profile">
      </Route>
    </>
  );
};



export default CustomerRoutes;
