import React from 'react';
import PropTypes from 'prop-types';
import {CartesianGrid, Line, LineChart, XAxis, YAxis} from "recharts";
import styles from "./ScoreGraph.module.scss";

const ScoreGraph = props => {

  let data = props.scoreHistoryData.map(d => {
    return {
      name:        d.date,
      score:       d.score,
      global:      d.global,
      industryAvg: d.industryAvg
    }
  });

  return (
    <div className={styles.score}>
      <div className={styles.title}>Score over time</div>

      <LineChart
        width={500}
        height={100}
        data={data}
        margin={{
          top:    5,
          right:  30,
          left:   20,
          bottom: 5,
        }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor="#092247" stopOpacity={0.1}/>
            <stop offset="100%" stopColor="#092247" stopOpacity={1}/>
          </linearGradient>
          <linearGradient id="colorUw" x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor="#8FBEA7" stopOpacity={0.1}/>
            <stop offset="100%" stopColor="#8FBEA7" stopOpacity={1}/>
          </linearGradient>
          <linearGradient id="colorUx" x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor="#A9A2F3" stopOpacity={0.1}/>
            <stop offset="100%" stopColor="#A9A2F3" stopOpacity={1}/>
          </linearGradient>

          <linearGradient id="lineGrad" x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor="#C5CADA" stopOpacity={0.1}/>
            <stop offset="100%" stopColor="#C5CADA" stopOpacity={1}/>
          </linearGradient>

        </defs>

        <CartesianGrid strokeDasharray="1 1" horizontal={false} color={{stroke: "url(#lineGrad)"}}/>
        <XAxis dataKey="name" axisLine={{stroke: "#C5CADA"}} tickLine={{stroke: "#C5CADA"}} tick={{fill: "#C5CADA"}}/>
        <YAxis domain={[1, 100]} axisLine={{stroke: "#C5CADA"}} tickLine={{stroke: "#C5CADA"}}
               tick={{fill: "#C5CADA"}}/>

        <Line dataKey="score" stroke="url(#colorUv)" strokeWidth={2} dot={false}/>
        <Line dataKey="global" stroke="url(#colorUw)" strokeWidth={2} dot={false}/>
        <Line dataKey="industryAvg" stroke="url(#colorUx)" strokeWidth={2} dot={false}/>

      </LineChart>

      <div className={styles.legend}>
        <div className={styles.item}>
          <img src='/assets/reports/ic_16px_SOC.png' alt={"Soc shield"}/>
          <span>SOC score</span>
        </div>
        <div className={styles.item}>
          <img src='/assets/reports/ic_16px_global_comparison.png' alt={"Globe symbol"}/>
          <span>Global Comparison</span>
        </div>
        <div className={styles.item}>
          <img src='/assets/reports/ic_16px_industry_avg.png' alt={"Average symbol"}/>
          <span>Industry Avg</span>
        </div>
      </div>

    </div>
  );
};

ScoreGraph.propTypes = {
  scoreHistoryData: PropTypes.arrayOf(PropTypes.shape({
    date:        PropTypes.string.isRequired,
    score:       PropTypes.number.isRequired,
    global:      PropTypes.number.isRequired,
    industryAvg: PropTypes.number.isRequired,
  })),
};

ScoreGraph.defaultProps = {
  scoreHistoryData: []
};

export default ScoreGraph;
