/**
 * Created by Constantine Kuks on 18/05/2021.
 */

import React, {useEffect, useState} from 'react';
import {withLocalize} from 'react-localize-redux';
import PropTypes from 'prop-types';
import ReportHeader from "./ReportHeader";
import styles from './Report.module.scss';
import ReportRightPane from "./ReportRightPane";
import ReportLeftPane from "./ReportLeftPane";
import {useDispatch, useSelector} from "react-redux";
import reportActions from "../../store/actions/reportActions";

const Report = props => {

  const [report, setReport] = useState(props.reportData);
  const fullReports = useSelector(state => state.report.fullReports);
  const reportList = useSelector(state => state.report.list);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.id) {
      dispatch(reportActions.getReport(props.id));
    }
    // eslint-disable-next-line
  },[]);

  useEffect(() => {
    const rep = fullReports.filter(report => report.uuid === props.id);
    if (rep[0]) {
      setReport(rep[0]);
    }
  }, [fullReports, props.id]);

  const {
    secOpsScore, secVisScore, secReadyScore, statistics
  } = report;

  return (
    <div className={styles.report}>
      <ReportHeader reportId={report.uuid}
                    logo={report.customer ? report.customer.logoPath : ''}
                    timeframe={report.dateRange}
                    className={styles.header}
                    unresolved={statistics.unresolvedPerSeverity}
                    ttdr={statistics.ttdr}
                    statistics={statistics}
                    reportList={reportList}
                    userType={props.userType}/>
      <div className={styles.body}>
        <ReportLeftPane className={styles['left-pane']} secOpsScore={secOpsScore} secVisScore={secVisScore}
                        secReadyScore={secReadyScore} scoreHistoryData={report.scoreHistoryData}/>
        <ReportRightPane className={styles['right-pane']}
                         {...report}
        />
      </div>

    </div>
  );
};
Report.defaultProps = {
  reportData:   {
    dateRange:              "Loading",
    secOps:                 [],
    secOpsRecommendation:   "Loading",
    secOpsScore:            0,
    secReady:               [],
    secReadyRecommendation: "Loading",
    secReadyScore:          0,
    secVis:                 [],
    secVisRecommendation:   "Loading",
    secVisScore:            0,
    statistics:             {
      ttdr:                  0,
      unresolvedPerSeverity: {
        critical: 0,
        high:     0,
        medium:   0,
        low:      0,
      },
      incidentsPerSeverity: {
        critical: 0,
        high:     0,
        medium:   0,
        low:      0,
      },
      perCategory: []
    },
    secOpsScoreHistory:     [],
    secReadyScoreHistory:   [],
    secVisScoreHistory:     [],
  },
  customerLogo: "",
  userType: PropTypes.string,
};

Report.propTypes = {
  reportData:   PropTypes.shape({
    dateRange:              PropTypes.string.isRequired,
    secOps:                 PropTypes.arrayOf(PropTypes.shape({
      topic:      PropTypes.string.isRequired,
      solution:   PropTypes.string.isRequired,
      note:       PropTypes.string.isRequired,
      compliance: PropTypes.number.isRequired,
      risk:       PropTypes.number.isRequired,
      impact:     PropTypes.number.isRequired,
    })).isRequired,
    secOpsRecommendation:   PropTypes.string.isRequired,
    secOpsScore:            PropTypes.number.isRequired,
    secReady:               PropTypes.arrayOf(PropTypes.shape({
      topic:      PropTypes.string.isRequired,
      solution:   PropTypes.string.isRequired,
      note:       PropTypes.string.isRequired,
      compliance: PropTypes.number.isRequired,
      risk:       PropTypes.number.isRequired,
      impact:     PropTypes.number.isRequired,
    })).isRequired,
    secReadyRecommendation: PropTypes.string.isRequired,
    secReadyScore:          PropTypes.number.isRequired,
    secVis:                 PropTypes.arrayOf(PropTypes.shape({
      topic:      PropTypes.string.isRequired,
      solution:   PropTypes.string.isRequired,
      note:       PropTypes.string.isRequired,
      compliance: PropTypes.number.isRequired,
      risk:       PropTypes.number.isRequired,
      impact:     PropTypes.number.isRequired,
    })).isRequired,
    secVisRecommendation:   PropTypes.string.isRequired,
    secVisScore:            PropTypes.number.isRequired,
    statistics:             PropTypes.shape({
      ttdr:                  PropTypes.number.isRequired,
      unresolvedPerSeverity: PropTypes.shape({
        critical: PropTypes.number.isRequired,
        high:     PropTypes.number.isRequired,
        medium:   PropTypes.number.isRequired,
        low:      PropTypes.number.isRequired,
      }),
    }).isRequired,
    secOpsScoreHistory:     PropTypes.arrayOf(PropTypes.number).isRequired,
    secReadyScoreHistory:   PropTypes.arrayOf(PropTypes.number).isRequired,
    secVisScoreHistory:     PropTypes.arrayOf(PropTypes.number).isRequired,
    scoreHistoryData:       PropTypes.arrayOf(PropTypes.shape({
      date:        PropTypes.string.isRequired,
      score:       PropTypes.number.isRequired,
      global:      PropTypes.number.isRequired,
      industryAvg: PropTypes.number.isRequired,
    })),
  }),
  customerLogo: PropTypes.string.isRequired,
  id:           PropTypes.string
};

export default withLocalize(Report);