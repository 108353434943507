if (!window.localize) {
  window.localize = {};
}

window.localize.en = {
  reports: {
    legend:         {
      total:       'Average score',
      operational: 'Operational',
      readiness:   'Readiness',
      visibility:  'Visibility',
    },
    summary:        {
      hint: {
        operational: 'Daily/ routine activities carried out to ensure the environment stays secure.',
        readiness:   'Security solutions that give coverage of the environment as recommended by CyberSOC Africa.',
        visibility:  'Gives insights into the external security posture of the environment.',
      }
    },
    recommendation: 'Recommendation',
    filterBy:       'Filter by',
    import:         {
      title:   'Import report',
      customer: 'Customer',
      month: `Please choose the report's month`,
      file: 'Please choose a file',
      import: 'Import',
      cancel: 'Cancel',
      template: {
        download: 'Download template',
        hint: 'Please download the template and fill in the details. Then upload the file here.',
      }
    }
  },
  nav:     {
    admin:    'Admin',
    adminNav: {
      customers: 'Customers',
      users:     'users',
      settings:  'settings'
    },
    back:     "Back",
    reports:  'Reports',
    auth:     {
      login:          "Log In",
      signup:         "Sign Up",
      logout:         "Logout",
      changePassword: "Change Password"
    }
  }

};

module.exports = window.localize.en;
