import React, {useEffect} from 'react';
import classnames from 'classnames';
import styles from './ReportsImportForm.module.scss';
import {useHistory} from 'react-router';
import {Translate} from 'react-localize-redux';
import {useDispatch, useSelector} from 'react-redux';
import reportActions from '../../store/actions/reportActions';
import customerActions from '../../store/actions/customerActions';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import uiActions from '../../store/actions/uiActions';

const ReportsImportForm = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const uiReportState = useSelector(state => state.ui.report);

  const initialValues = {
    customerUuid: '',
    dateRange:    '',
    file:         ''
  }
  const validationSchema = Yup.object().shape({
    customerUuid: Yup.string().required('Customer is required'),
    dateRange:    Yup.string().required('Month is required'),
    file:         Yup.mixed().required('File is required')
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => {
      handleSubmit(values);
    },
  })

  const customerState = useSelector(state => state.customer);

  // On component mount
  useEffect(() => {
    dispatch(customerActions.getCustomers());
  }, [dispatch]);

  useEffect(() => {
    if (uiReportState.reportCreationState === 'success' && uiReportState.isCreatingReport === false){
      dispatch(uiActions.setReportCreationState(undefined));
      history.push('/reports');
    }
  }, [dispatch, history, uiReportState]);

  const handleCancel = () => {
    history.push('/reports');
  }
  const handleSubmit = (values, actions) => {
    dispatch(reportActions.importReport(values));
  }

// xlsx file import form with customer dropdown and month selection
  return (
    <div className={styles['importForm']}>
      <div className={styles['importForm__body']}>
        <div className={styles['importForm__header']}>
          <h3>
            <Translate id="reports.import.title"/>
          </h3>
          <strong className="Toastify__toast--warning">Warning, this is an experimental addition, use only for testing for now!</strong>
          <p>
            <a href="/CISO-Report-Template.xlsx"><Translate id="reports.import.template.download"/></a><br/>
            <small><Translate id="reports.import.template.hint"/></small>
          </p>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className={styles.importForm__item}>
            <div className={styles.importForm__item__label}>
              <Translate id="reports.import.customer"/>
            </div>
            <select name="customerUuid" className={styles.importForm__item__input} defaultValue={0} onChange={formik.handleChange}>
              <option value={0}>Please select customer</option>
              {customerState.list.map(cust => (<option key={cust.uuid} value={cust.uuid}>{cust.name}</option>))}
            </select>
            {formik.errors.customerUuid && <p className={styles.importForm__item__error}>Customer selection is needed!</p>}
          </div>


          <div className={styles.importForm__item}>
            <div className={styles.importForm__item__label}>
              <Translate id="reports.import.month"/>
            </div>
            <input
              type="month"
              name="dateRange"
              className={styles.importForm__item__input}
              onChange={formik.handleChange}
              onClick={(e) => e.target.showPicker()}
            />
            {formik.errors.dateRange && <p className={styles.importForm__item__error}>Month selection is needed!</p>}
          </div>

          <div className={styles.importForm__item}>
            <div className={styles.importForm__item__label}>
              <Translate id="reports.import.file"/>
            </div>
            <input
              accept={'.xlsx'}
              type="file"
              name="file"
              className={styles.importForm__item__input}
              onChange={(e) => {
                  const fileReader = new FileReader();
                  fileReader.onload = () => {
                    if (fileReader.readyState === 2) {
                      formik.setFieldValue('file', fileReader.result);
                    }
                  };
                  fileReader.readAsDataURL(e.target.files[0]);
              }}/>
            {formik.errors.file && <p className={styles.importForm__item__error}>File is needed!</p>}
          </div>

          <div className={styles.importForm__buttons}>

            <button type="submit"
                    className={classnames(styles.importForm__button, styles.importForm__button__import)}>
              <Translate id="reports.import.import"/>
            </button>

            <button type={'button'}
                    onClick={handleCancel}
                    className={classnames(styles.importForm__button, styles.importForm__button__cancel)}>
              <Translate id="reports.import.cancel"/>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

ReportsImportForm.propTypes = {}

export default ReportsImportForm;
