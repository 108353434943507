import axios from "axios";
import {userBaseActions} from "../reducers/user";
import uiActions from '../actions/uiActions';
import authActions from "./authActions";

export const createUser = ({name, email, firstName, lastName, phone, password, customerUuid, userType, role}) => {
  return (dispatch) => {
    dispatch(uiActions.setIsCreatingUser(true));
    axios.post(`/api/user/user`, {name, email, firstName, lastName, phone, password,customerUuid,userType, role}, {withCredentials: true})
      .then((response) => {
        dispatch(userBaseActions.addUserToList(response.data.user));
        dispatch(uiActions.setUserCreationState('success'));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401){
          dispatch(authActions.logout());
        }
        dispatch(uiActions.setUserCreationState('error'));
        dispatch(uiActions.setError({message:"Failed to create user"}));
      })
      .finally(() => {
        dispatch(uiActions.setIsCreatingUser(false));
      });
  };
};

export const updateUser = (uuid ,{name, email, firstName, lastName, phone, password, customerUuid, userType, role}) => {
  return (dispatch) => {
    dispatch(uiActions.setIsCreatingUser(true));
    axios.put(`/api/user/user/`+uuid, {name, email, firstName, lastName, phone, password, customerUuid,userType, role}, {withCredentials: true})
      .then((response) => {
        dispatch(userBaseActions.updateUserList(response.data.user));
        dispatch(uiActions.setUserCreationState('success'));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401){
          dispatch(authActions.logout());
        }
        dispatch(uiActions.setUserCreationState('error'));
        dispatch(uiActions.setError({message:"Failed to update user"}));
      })
      .finally(() => {
        dispatch(uiActions.setIsCreatingUser(false));
      });
  };
};

export const deleteUser = (uuid) => {
  return (dispatch) => {
    axios.delete(`/api/user/user/${uuid}`, {withCredentials: true})
      .then((response) => {
        dispatch(userBaseActions.removeUserFromList(response.data.user.uuid));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401){
          dispatch(authActions.logout());
        }
        dispatch(uiActions.setError({message:"Failed to delete user"}));
      })
  };
};

export const getUsers = () => {
  return (dispatch) => {
    axios.get(`/api/user/users`, {withCredentials: true})
      .then((response) => {
        dispatch(userBaseActions.setUserList(response.data.users));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401){
          dispatch(authActions.logout());
        }
        dispatch(uiActions.setError({message:"Failed to fetch users"}));
      })
  };
};

export const changePassword = ({password, newPassword, newPasswordRepeat}) => {
  return (dispatch) => {
    dispatch(uiActions.setIsChangingPassword(true));
    axios.post(`/api/changePassword`, {password, newPassword, newPasswordRepeat}, {withCredentials: true})
      .then((response) => {
        dispatch(userBaseActions.setUserList(response.data.users));
        dispatch(uiActions.setSuccess({message: "Password changed successfully"}));
        dispatch(uiActions.setChangePasswordState('success'));
      })
      .catch((err) => {
        dispatch(uiActions.setError({message:"Failed to change password"}));
      })
      .finally(() => {
        dispatch(uiActions.setIsChangingPassword(false));
      })
  };
};


const allUserActions = {
  ...userBaseActions,
  createUser,
  deleteUser,
  getUsers,
  changePassword,
  updateUser
};


export default allUserActions;
