import React from 'react';
import PropTypes from 'prop-types';
import './TableHeader.scss';
import classNames from "classnames";
import Filter from "./Filter";

class TableHeader extends React.Component {

    state = {
      isFiltered: false
    };

    render() {
        return (
            <div className={classNames("table--header")}>
                <Filter/>

                <div className={classNames("score")}>
                    Potential score: {this.props.score.toFixed(0)}
                </div>
            </div>
        );
    }
}

TableHeader.propTypes = {
    onChange: PropTypes.func.isRequired,
    score: PropTypes.number.isRequired,
};

export default TableHeader;