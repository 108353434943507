/**
 * Created by Constantine Kuks on 18/05/2021.
 */

import React from 'react';
import {withLocalize, Translate} from 'react-localize-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {NavLink, withRouter} from 'react-router-dom';
import classes from './Navbar.module.css';
import userTypes, * as userRole from '../../common/userRoles';

const NavItem = (props) => {
  return (
    <li>
      <NavLink activeClassName='active-nav-link' className={classNames('text-color-nav', 'main-nav-links', classes.link)}
               to={props.to}><Translate id={props.translateId}/></NavLink>
    </li>
  );
};

const NavItems = (props) => {

  let nav = undefined;
  switch (props.userRole) {
    case userRole.ROLE_ADMIN:
      nav = <React.Fragment>
        <NavItem to='/admin' translateId='nav.admin'/>
        <NavItem to='/reports' translateId='nav.reports'/>
      </React.Fragment>;
      break;
    case userRole.ROLE_USER:
      nav = <React.Fragment>
        <NavItem to='/reports' translateId='nav.reports'/>
      </React.Fragment>;
      break;
    case userRole.ROLE_CLIENT:
    default:
      nav = <React.Fragment>
        <NavItem to='/reports' translateId='nav.reports'/>
      </React.Fragment>;
      break;
  }

  return (
    <ul className={classNames(props.className)}>
      {nav}
    </ul>
  );
};

NavItems.propTypes = {
  className: PropTypes.string,
  userRole: PropTypes.oneOf(userTypes)
};

export default withLocalize(withRouter(NavItems));

