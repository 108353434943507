import React from 'react';
import PropTypes from 'prop-types';
import styles from './Modal.module.scss';
import * as ReactDOM from "react-dom";
import classnames from 'classnames';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const WaitingOverlay = props => {
  if (!props.isShown) return null;

  return ReactDOM.createPortal(
    <>
      <div className={classnames(styles.backdrop)}/>
      <div className={classnames(styles.modal, props.className)}>
        <FontAwesomeIcon icon={faSpinner} spin style={{fontSize:36}}/>
      </div>
    </>
    , document.getElementById('modal-root'));
};

WaitingOverlay.propTypes = {
  isShown:   PropTypes.bool.isRequired,
  className: PropTypes.string
};

export default WaitingOverlay;
