import React from "react";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import Scrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.min.css';

import './Table.scss';
import * as PropTypes from "prop-types";
import {withLocalize} from "react-localize-redux";
import {connect} from "react-redux";


const style = {
  green:      {
    color: '#27AE6D'
  },
  orange:     {
    color: '#FFC907'
  },
  darkOrange: {
    color: '#EE8F00'
  },
  red:        {
    color: '#DA2E49'
  },
  grey:       {
    color: 'grey'
  },
  infoBlue:   {
    color: '#00ccff'
  }
};


class Table extends React.Component {

  _complianceRender(row) {
    let spanStyle;
    let text;
    switch (row.value) {
      case 0:
        spanStyle = style.red;
        text = 'Non-compliant';
        break;
      case 1:
        spanStyle = style.orange;
        text = 'Partial (50%)';
        break;
      case 2:
        spanStyle = style.orange;
        text = 'Partial (75%)';
        break;
      case 3:
        spanStyle = style.green;
        text = 'Pass';
        break;
      default:
        spanStyle = style.grey;
        text = 'N/A';
        break;
    }

    return (
      <span style={{...spanStyle}}>
                     {text}
                </span>);
  }

  _RiskRender(row) {
    let img;
    let spanStyle;
    let text;
    switch (row.value) {
      case 0:
        img = "/assets/reports/severities/low.png";
        spanStyle = style.green;
        text = 'Low';
        break;
      case 1:
        img = "/assets/reports/severities/medium.png";
        spanStyle = style.orange;
        text = 'Medium';
        break;
      case 2:
        img = "/assets/reports/severities/high.png";
        spanStyle = style.darkOrange;
        text = 'High';
        break;
      case 3:
        img = "/assets/reports/severities/critical.png";
        spanStyle = style.red;
        text = 'Critical';
        break;
      default:
        img = "/assets/reports/severities/info.png";
        spanStyle = style.grey;
        text = 'N/A';
        break;
    }

    return (
      <span className="risk" style={{...spanStyle}}>
                     <img src={img} alt={`Severity icon for ${text}`}/>{text}
                </span>);
  }

  _ImpactRender(row) {
    let spanStyle;
    let text;
    let value = parseFloat(row.value);

    if (value >= 0) {
      spanStyle = style.green;
      text = '+' + value.toFixed(0);
    } else {
      spanStyle = style.red;
      text = value.toFixed(0);
    }

    return (
      <span className="impact" style={{...spanStyle}}>
                     {text}
                </span>);
  }

  CustomTbodyComponent = (props) => (
    <div {...props} className={"rt-tbody " + (props.className || '')}>
      <Scrollbar ref={(ref) => {
      }} >{props.children}</Scrollbar>
    </div>
  );

  render() {
    let {data, riskFilter, complyFilter} = this.props;

    let finalData = [...data];

    if (riskFilter.length > 0){
      finalData = finalData.filter(data => riskFilter.indexOf(data.risk)!== -1);
    }
    if (complyFilter.length > 0){
      finalData = finalData.filter(data => complyFilter.indexOf(data.compliance)!== -1);
    }

    const columns = [
      {Header: 'Topic   :', accessor: 'topic', headerClassName: "column--headers", className: "row--style topic", width: 130},
      {Header: 'Solution   :', accessor: 'solution', headerClassName: "column--headers", className: "row--style"},
      {Header: 'Note   :', accessor: 'note', headerClassName: "column--headers", className: "row--style"},
      {
        Header:          'Comply   :',
        accessor:        'compliance',
        Cell:            this._complianceRender,
        headerClassName: "column--headers",
        className:       "row--style",
        width: 117
      },
      {
        Header:          'Risk   :',
        accessor:        'risk',
        headerClassName: "column--headers",
        className:       "row--style",
        Cell:            this._RiskRender,
        width: 110
      },
      {
        Header:          'Impact   :',
        accessor:        'impact',
        headerClassName: "column--headers",
        className:       "row--style",
        Cell:            this._ImpactRender,
        width: 80
      },
    ];

    // Render the UI for your table
    return (
      <>
        <ReactTable
          className="table--style"
          sortable={true}
          data={finalData}
          columns={columns}
          TbodyComponent={this.CustomTbodyComponent}
          style={{height:"90%"}}
          minRows={0}
          showPaginationTop={false}
          showPaginationBottom={false}
        />
      </>
    )
  }
}

Table.propTypes = {
  data: PropTypes.any,
  riskFilter: PropTypes.array,
  complyFilter: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    riskFilter:   state.report.cardListFilter.risk,
    complyFilter: state.report.cardListFilter.comply,
  }
}

export default connect(mapStateToProps)(withLocalize(Table));