/**
 * Created by Constantine Kuks on 18/05/2021.
 */

export const USER_TYPE_CUSTOMER = 'client';
export const USER_TYPE_COMPANY = 'company';

const  USER_TYPES = [
  USER_TYPE_CUSTOMER,
  USER_TYPE_COMPANY
];

export default USER_TYPES;