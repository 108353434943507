import React from 'react';
import styles from './HeaderActions.module.scss';
import {withRouter} from "react-router-dom";
import axios from 'axios';
import PropTypes from 'prop-types';
import {USER_TYPE_COMPANY} from '../../../../common/userTypes';

const HeaderActions = props => {

  const handleExport = () => {
    axios.get(`/api/report/report/export/${props.reportId}`, { responseType: "blob"}).then(result=>{


      const url = window.URL.createObjectURL(
        new Blob([result.data]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `test.xlsx`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  }

  return (
    <div className={styles.actionWrapper}>
      {/*<button>Give feedback</button>*/}
      { props.userType === USER_TYPE_COMPANY && <button onClick={handleExport}><img src="/assets/reports/export.png" alt="Export button icon"/>Export Report</button>}
      <button onClick={() => props.history.push('/reports/')} className={styles.backBtn}>Back</button>
    </div>
  );
};

HeaderActions.propTypes = {
  reportId: PropTypes.string.isRequired,
  userType: PropTypes.string,
};

export default withRouter(HeaderActions);
