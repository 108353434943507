/**
 * Created by Constantine Kuks on 18/05/2021.
 */

import {renderToStaticMarkup} from "react-dom/server";

window.localize = {
  languages: [
    {name: "English", code: "en"}
  ],
  options  : {
    ignoreTranslateChildren: false,
    defaultLanguage        : "en",
    renderToStaticMarkup
  }
};

// module.exports = window.localize;