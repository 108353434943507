import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Hint} from "../../../common/Hint";
import {Translate, withLocalize} from "react-localize-redux";
import classNames from "classnames";
import "./Card.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import Score from "./Score";
import Recommendation from "./Recommendation";
import TableHeader from "./TableHeader";
import Table from "./Table";

const IconSelect = ({title}) => {
  switch (title) {
    case "Operational":
      return <img alt="Operational icon" src="/assets/reports/ic_16px_operational.svg"/>
    case "Readiness":
      return <img alt="Readiness icon" src="/assets/reports/ic_16px_readiness.svg"/>
    case "Visibility":
      return <img alt="Visibility icon" src="/assets/reports/ic_16px_visibility.svg"/>
    default:
      return null
  }
};

export class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false
    }
  }

  toggleExpand = () => {
    if (!this.state.isExpanded && this.props.onOpen) {
      this.props.onOpen(this.props.title);
    } else if (this.state.isExpanded && this.props.onClose) {
      this.props.onClose()
    }
    this.setState({
      isExpanded: !this.state.isExpanded
    });

  }

  closeSummary = () => {
    this.setState({
      isExpanded: false
    });
  }

  /**
   *
   * @param {[String]}values
   */
  onFilter = (values) => {
    // TODO
  }

  render() {
    const {props, state} = this;

    const {isMinimized} = this.props;
    let isInc = false;

    if (props.score > props.scoreHistory[this.props.scoreHistory.length - 1]) {
      isInc = true;
    }

    return (
      <div className={classNames('card')}>

        {/* Header of the card */}
        <hgroup className={classNames('card--header')} onClick={this.toggleExpand}>
          <IconSelect title={props.title}/>
          <h1>{props.title}</h1>
          <Hint hintText={<Translate id={"reports.summary.hint." + props.title.toLocaleLowerCase()}/>}
                position="right"/>
          <FontAwesomeIcon className={classNames('expander')}
                           icon={(state.isExpanded ? faChevronDown : faChevronRight)}/>
        </hgroup>

        {!isMinimized &&
        <>
          {/* Summarized details */}
          <div className={classNames("card--subheader", {open:this.state.isExpanded})}>

            <div className={classNames('card--subheader--score')}>
              <Score isInc={isInc} value={props.score.toFixed(0)} graphData={props.scoreHistory}/>
            </div>

            <div className={classNames('card--subheader--recommendations')}>
              <Recommendation recommendationText={props.recommendation} isOpen={this.state.isExpanded}/>
            </div>

          </div>

          {/* Expanded table */}

          <div className={classNames("card--table--container", {expand: state.isExpanded})}>
            <div className={classNames("card--table--header")}>
              <TableHeader onChange={this.onFilter} score={props.score}/>
            </div>

            {/*<div classNabme={classNames("card--table")}>*/}
            <Table data={props.data}/>
            {/*</div>*/}
          </div>
        </>

        }
      </div>
    );
  }
}

Card.propTypes = {
  title:          PropTypes.oneOf(['Operational', 'Readiness', 'Visibility']).isRequired,
  recommendation: PropTypes.string.isRequired,
  data:           PropTypes.arrayOf(PropTypes.shape({
    topic:      PropTypes.string.isRequired,
    solution:   PropTypes.string.isRequired,
    note:       PropTypes.string.isRequired,
    compliance: PropTypes.number.isRequired,
    risk:       PropTypes.number.isRequired,
  })).isRequired,
  score:          PropTypes.number.isRequired,
  scoreHistory:   PropTypes.arrayOf(PropTypes.number).isRequired,
  onOpen:         PropTypes.func.isRequired,
  onClose:        PropTypes.func.isRequired,
  isMinimized:    PropTypes.bool,
};

Card.defaultProps = {
  recommendation: 'none',
  data:           [],
  score:          0,
  isInc:          false,
  scoreHistory:   [1, 2, 3],
  isMinimized:    false
}

export default withLocalize(Card);