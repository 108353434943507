import React from 'react';
import PropTypes from 'prop-types';
import DropdownInput from "../../../common/DropdownInput";

const options = [
  {
    name:  "Fully compliant",
    value: 3
  }, {
    name:  "75% compliant",
    value: 2
  }, {
    name:  "50% compliant",
    value: 1
  }, {
    name:  "Non compliant",
    value: 0
  },
];

const ComplyDropdown = props => {
  return (
    <DropdownInput onChange={props.onChange} options={options} hasLabel={false}
                   name={props.name.toLocaleLowerCase() || "comply"} isRequired={false} value={props.value}/>
  );
};

ComplyDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  value:    PropTypes.number.isRequired,
  name:     PropTypes.string,
};

export default ComplyDropdown;
