import React from 'react';
import StatisticsSection from "./StatisticsSection";
import styles from './StatisticsSLA.module.scss';
import StatisticItem from './StatisticItem'

const StatisticsSLA = props => {
  return (
    <StatisticsSection title={"Statistics"} childrenClassName={styles.wrapper}>
      <StatisticItem value={props.mttd} title={"Mean Time To Detect"} subtitle={"Hr / Alert"}/>
      <StatisticItem value={props.mttr} title={"Mean Time To Resolve"} subtitle={"Hr / Alert"}/>
      <StatisticItem value={props.avgIncPerWorkingDays} title={"Average alerts per day"}/>
      <StatisticItem value={props.avgIncPerWorkingWeekend} title={"Average alerts per week"}/>
      <StatisticItem value={props.numOfAnalysts} title={"Total use cases  / Total applicable use cases"}/>
      <StatisticItem value={props.avgIncPerAnalyst} title={"Total Log Sources /Total Log Sources Available"}/>
    </StatisticsSection>
  );
};

StatisticsSLA.propTypes = {

};

export default StatisticsSLA;
