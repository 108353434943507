import axios from "axios";
import {customerBaseActions} from "../reducers/customer";
import uiActions from '../actions/uiActions';
import authActions from "./authActions";

export const createCustomer = (name, customerCode, customerLogo) => {
  return (dispatch) => {
    dispatch(uiActions.setIsCreatingCustomer(true));
    axios.post(`/api/customer/customer`, {name, customerCode, customerLogo}, {withCredentials: true})
      .then((response) => {
        dispatch(customerBaseActions.addCustomerToList(response.data.customer));
        dispatch(uiActions.setCustomerCreationState('success'));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401){
          dispatch(authActions.logout());
        }
        dispatch(uiActions.setCustomerCreationState('error'));
        dispatch(uiActions.setError({message:"Failed to create customer"}));
      });
  };
};

export const deleteCustomer = (uuid) => {
  return (dispatch) => {
    axios.delete(`/api/customer/customer/${uuid}`, {withCredentials: true})
      .then((response) => {
        dispatch(customerBaseActions.removeCustomerFromList(response.data.customer.uuid));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401){
          dispatch(authActions.logout());
        }
        dispatch(uiActions.setError({message:"Failed to delete customer"}));
      })
  };
};

export const getCustomers = () => {
  return (dispatch) => {
    dispatch(uiActions.setCustomerFetchState(true));
    axios.get(`/api/customer/customers`, {withCredentials: true})
      .then((response) => {
        dispatch(customerBaseActions.setCustomerList(response.data.customers));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(authActions.logout());
        }
        dispatch(uiActions.setError({message: 'Failed to fetch customers'}));
      })
      .finally(() => {
        dispatch(uiActions.setCustomerFetchState(false));
      })
  };
};

export const updateCustomer = (uuid, name, customerCode, customerLogo) => {
  return async (dispatch) => {
    dispatch(uiActions.setIsCreatingCustomer(true));
    try  {
    const response =  await axios.put(`/api/customer/customer/${uuid}`,{name, customerCode, customerLogo}, {withCredentials: true})
    dispatch(customerBaseActions.updateCustomerList(response.data.customer));
    dispatch(uiActions.setCustomerCreationState('success'));

    } catch(err) {
      if (err.response && err.response.status === 401){
        dispatch(authActions.logout());
      }
      dispatch(uiActions.setCustomerCreationState('error'));
      dispatch(uiActions.setError({message:"Failed to update customers"}));
    }
  };
};


const allCustomerActions = {
  ...customerBaseActions,
  createCustomer,
  deleteCustomer,
  getCustomers,
  updateCustomer
};


export default allCustomerActions;
