import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './StatisticsPane.module.scss';
import classnames from "classnames";
import StatisticsInBySev from "./StatisticsInBySev";
import StatisticsInPerCat from "./StatisticsInPerCat";
import StatisticsSLA from "./StatisticsSLA";
import StatisticsEvent from "./StatisticsEvent";

const StatisticsPane = props => {

  const [firstRun, setFirstRun] = useState(true);

  useEffect(() => {
    if (props.isOpen && firstRun){
      setFirstRun(false);
    }
// eslint-disable-next-line
  }, [props]);


  return (
    <div className={classnames(styles.wrapper, {[styles.open]: props.isOpen, [styles.close]: !props.isOpen && !firstRun})}>
      <div className={styles.leftPane}>
        <StatisticsInBySev incidentsBySeverity={props.statistics.incidentsPerSeverity}/>
        <StatisticsInPerCat incidentsPerCategory={props.statistics.perCategory}/>
      </div>
      <div className={styles.divider}> </div>
      <div className={styles.rightPane}>
        <StatisticsSLA {...props.statistics}/>
        <StatisticsEvent {...props.statistics}/>
      </div>
    </div>
  );
};

StatisticsPane.propTypes = {
  statistics: PropTypes.object,
  isOpen:     PropTypes.bool.isRequired,
};

export default StatisticsPane;
