import React from 'react';
import PropTypes from 'prop-types';
import styles from './ReportLeftPane.module.scss';
import {Legend} from "./dashboard/Graphs/Legend/Legend";
import Chart from "./dashboard/Graphs/Chart/Chart";
import ScoreGraph from "./dashboard/Graphs/ScoreGraph/ScoreGraph";

const ReportLeftPane = props => {
  const {secReadyScore, secVisScore, secOpsScore} = props;
  const score = (secOpsScore + secVisScore + secReadyScore) / 3;
  return (
    <div className={props.className}>
      <div className={styles.container}>
        <img src='/assets/cyberSOC_logo.png' alt={"CyberSoc Logo"}/>
        <Legend score={score} operational={secOpsScore} readiness={secReadyScore} visibility={secVisScore}/>
        <Chart score={score} operational={secOpsScore} readiness={secReadyScore} visibility={secVisScore}/>
        <ScoreGraph scoreHistoryData={props.scoreHistoryData}/>
      </div>
    </div>
  );
};

ReportLeftPane.propTypes = {
  secOpsScore:   PropTypes.number.isRequired,
  secReadyScore: PropTypes.number.isRequired,
  secVisScore:   PropTypes.number.isRequired,
  scoreHistoryData: PropTypes.arrayOf(PropTypes.shape({
    date:        PropTypes.string.isRequired,
    score:       PropTypes.number.isRequired,
    global:      PropTypes.number.isRequired,
    industryAvg: PropTypes.number.isRequired,
  })),
};

ReportLeftPane.defaultProps = {
  secOpsScore:   0,
  secReadyScore: 0,
  secVisScore:   0,
  scoreHistoryData:[]
}

export default ReportLeftPane;
