import React from 'react';
import './SecurityTableRow.scss';


const SecurityTableRowHeader = props => {


  return (
    <tr>
      <th className={"topic"}>Topic</th>
      <th className={"description"}>Description</th>
      <th className={"solution"}>Solution</th>
      <th className={"note"}>Note</th>
      <th className={"compliance"}>Compliance</th>
      <th className={"risk"}>Risk</th>
      <th className={"impact"}>Impact</th>
    </tr>
  );
};

SecurityTableRowHeader.propTypes = {
};

export default SecurityTableRowHeader;
