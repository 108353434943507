import React from 'react';
import SecurityTable from "./Components/SecurityTable";
import PropTypes from "prop-types";

const rows = [
  { topic:"Vulnerability Scanner and Management", description:"Do you have a vulberability scanner?  When was the last one? When is the next one? How frequently do you carry out vulnerabilty assessment ? Show report and plan"},
  { topic:"Business Application Monitoring", description:"Do you have any external application critical to your business (eg SWIFT) ? If yes, It is being monitored externally or internally ? Evidence"},
  { topic:"Cloud Platform Monitoring", description:"Do you have any service or servers hosted in the cloud? ( eg. screen on shot of  cloudtrail and cloud watch for AWS)"},
  // { topic:"Cloud storage", description:"Do you have any service or servers hosted in the cloud? ( eg. screen on shot of  cloudtrail and cloud watch for AWS) if its onedrive? Is it being monitored?"},
  { topic:"Website Monitoring", description:"Who is monitoring your website? Do you get alerts from them if there is any issue? (Recommendation: loop us in on the alerts )"},
  // { topic:"Internet-facing Business Application", description:"Do you have any proprietary business application that is internet facing? (eg banking app) ? If yes, It is being monitored externally or internally ? Evidence"},
  { topic:"DNS registration", description:"who is your DNS registrant? Do you get alerts from them if there is any issue? (Recommendation: loop us in in the alerts )"},
  { topic:"Social Media Account Management", description:"What social media platforms are you on? Do you login with 2FA? Is the phone number and email attached to the social account owned by the organization? Has the marketing team attended the cybersecurity social media risk awareness?"},
  { topic:"Cyber Threat Intelligence Service", description:"Are you subscribed to any CTI service? Name?"},
  { topic:"Penetration Test Practice", description:"Do you do pentest in your environment? How frequently? Evidence"},
  { topic:"Secure Lab environment - Security Policy and Monitoring ", description:"Does your security policy scope covers the Lab/Staging environment and is it being enforced. Do you work with sensitive data in your lab environment? Is it being monitored?"}

]

const SecurityVisibilitySection = props => {
  return (
    <>
      <SecurityTable type={"Visibility"} rows={props.rows} reportId={props.reportId}/>
    </>
  );
};

SecurityVisibilitySection.propTypes = {
  rows: PropTypes.array,
  reportId: PropTypes.string,
};
SecurityVisibilitySection.defaultProps = {
  rows: rows
};



export default SecurityVisibilitySection;
