import React from 'react';
import styles from "./TextInput.module.scss";
import PropTypes from "prop-types";

const TextInput = (props) => {
  return (
    <>
      <div className={styles.control}>
        <label htmlFor={props.id}>{props.name}</label>
        <input type={props.type} id={props.id} required={props.isRequired} disabled={props.disabled} minLength={props.min} defaultValue={props.defaultValue}/>
        {props.hint && <small>{props.hint}</small>}
      </div>
    </>
  );
};

TextInput.propTypes = {
  name:         PropTypes.string.isRequired,
  type:         PropTypes.string,
  id:           PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  isRequired:   PropTypes.bool,
  disabled:     PropTypes.bool,
  min:          PropTypes.number,
  hint:         PropTypes.string,
  encType:      PropTypes.string,
}
TextInput.defaultProps = {
  type: "text",
}

export default TextInput;
