import React from 'react';
import PropTypes from 'prop-types';
import './SecurityTableRow.scss';

const StatisticsTableRow = props => {

  return (
    <tr>
      <td>
        <label htmlFor={props.id}>{props.topic}</label>
      </td>
      <td>
        {!props.component && <input id={props.id} type={props.type} name={props.id} step={props.type === 'number' ? 0.01 : undefined} />}
        {props.component && props.component}
      </td>
    </tr>
  );
};
StatisticsTableRow.propTypes = {
  topic:     PropTypes.string.isRequired,
  id:        PropTypes.string.isRequired,
  type:      PropTypes.string.isRequired,
  component: PropTypes.object,
};

export default StatisticsTableRow;
