import React from 'react';
import PropTypes from 'prop-types';
import StatisticsSection from "./StatisticsSection";
import styles from './StatisticsInPerCat.module.scss';
import Scrollbar from "react-perfect-scrollbar";

const StatisticsInPerCat = props => {
  const data = props.incidentsPerCategory.slice().sort((a, b) => b.value - a.value);

  return (
    <StatisticsSection title={"Alerts per Category"} childrenClassName={styles.categories}>
      <Scrollbar className={styles.scrollbar} options={{wheelSpeed: 0.2}} >
        {data.map(cat => <div key={cat.name} className={styles['categories__item']}>
          <span>{cat.value}</span><span>{cat.name}</span>
        </div>)}
      </Scrollbar>
    </StatisticsSection>
  );
};

StatisticsInPerCat.propTypes = {
  incidentsPerCategory: PropTypes.array
};

export default StatisticsInPerCat;
