import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Translate, withLocalize} from "react-localize-redux";
import './Legend.scss';

export const Legend = props => {
  const {score, operational, readiness, visibility} = props;
  
  let SingleCell = ({value, defaultText, icon, translationId}) => {
    
    return (
      <div className={classNames('legend__item')}>
        <div className={classNames('icon')}><img src={icon} alt={`Icon for ${defaultText} legend item`}/></div>
        <div className="legend__item__info">
          <div className="description"><Translate id={translationId}/></div>
          <div className={classNames('value')}>{value}</div>
        </div>
      </div>
    );
  };
  
  
  return (
    <div className="legend">
      <SingleCell value={score.toFixed(0)} defaultText={"Total"} icon='/assets/reports/ic_16px_SOC.svg' translationId="reports.legend.total"/>
      <SingleCell value={operational.toFixed(0)} defaultText={"Operational"} icon='/assets/reports/ic_16px_operational.svg' translationId="reports.legend.operational"/>
      <SingleCell value={readiness.toFixed(0)} defaultText={"Readiness"} icon='/assets/reports/ic_16px_readiness.svg' translationId="reports.legend.readiness"/>
      <SingleCell value={visibility.toFixed(0)} defaultText={"Visibility"} icon='/assets/reports/ic_16px_visibility.svg' translationId="reports.legend.visibility"/>
    </div>
  );
};

Legend.propTypes = {
  score: PropTypes.number.isRequired,
  operational: PropTypes.number.isRequired,
  readiness: PropTypes.number.isRequired,
  visibility: PropTypes.number.isRequired,
};

export default withLocalize(Legend);
