/**
 * Created by Constantine Moses Kuks on 11/13/18.
 */

import {localizeReducer} from 'react-localize-redux';
import authReducer from './auth';
import customerReducer from './customer';
import uiReducer from './ui';
import userReducer from './user';
import reportReducer from './reports';

const rootReducer = {
  localize: localizeReducer,
  customer: customerReducer,
  auth:     authReducer,
  ui:       uiReducer,
  user:     userReducer,
  report:   reportReducer
}

export default rootReducer;
