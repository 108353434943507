import {createLogicMiddleware} from "redux-logic";
import rootLogic from "./logics";
import rootReducer from "./reducers";
import {configureStore} from '@reduxjs/toolkit';
import { getDefaultMiddleware } from '@reduxjs/toolkit'

const middlewares = getDefaultMiddleware();
const logicMiddleware = createLogicMiddleware(rootLogic, {});
let allMiddlewares = middlewares.concat(logicMiddleware);


export default function config() {
  return configureStore({
    reducer: rootReducer,
    middleware: allMiddlewares
  });
}
