/**
 * Created by Constantine Kuks on 18/05/2021.
 */

import React, {useState} from 'react';
import {Translate} from 'react-localize-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withRouter} from "react-router";
import {useDispatch} from "react-redux";
import authActions from "../../store/actions/authActions";
import styles from './Auth.module.scss';
import Modal from "../modals/Modal";
import UserChangePasswordForm from "../common/user/UserChangePasswordForm";

const Auth = (props) => {

  const dispatch = useDispatch();
  const [isChangePasswords, setIsChangePasswords] = useState(false);

  const handleLogout = () => {
    dispatch(authActions.logout());
  }

  const handleToggleChangePassword = () => {
    setIsChangePasswords(!isChangePasswords);
  }

  return (
    <div>
      {!props.isAuthenticated &&
      <React.Fragment>
        {/*<Link to="/login"><Translate id='nav.auth.login'/></Link>*/}
        {/*<button className={classNames('button-style' )}><Translate id='nav.auth.signup'/></button>*/}
      </React.Fragment>
      }
      {props.isAuthenticated &&
      <React.Fragment>
        <button className={classNames(styles.button, styles.changePassword )} onClick={handleToggleChangePassword}><Translate id='nav.auth.changePassword'/></button>
        <button className={classNames(styles.button )} onClick={handleLogout}><Translate id='nav.auth.logout'/></button>
      </React.Fragment>
      }
      {isChangePasswords && <Modal className={styles.changePasswordModal} isShown={isChangePasswords} onClose={handleToggleChangePassword}>
        <UserChangePasswordForm onClose={handleToggleChangePassword}/>
      </Modal>}
    </div>
  );
};

Auth.propTypes = {
  isAuthenticated: PropTypes.bool
};

export default withRouter(Auth);
