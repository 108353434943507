import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import './Filter.scss';
import { withLocalize} from "react-localize-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faFilter} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import reportActions from "../../../../store/actions/reportActions";

const complianceOptions = [
  {value: 0, text: "Non compliant"},
  {value: 1, text: "50% compliant"},
  {value: 2, text: "75% compliant"},
  {value: 3, text: "100% compliant"}
];

const riskOptions = [
  {value: 0, text: "Low"},
  {value: 1, text: "Medium"},
  {value: 2, text: "High"},
  {value: 3, text: "Critical"}
];

export class Filter extends React.Component {

  state = {
    isHover:    false,
    isActive:   false,
    isDropdown: false,
    filterBy:   '',

  }

  _updateState = (param, value) => (e) => {
    e.stopPropagation();
    this.setState({
      [param]: value
    });
  }

  _updateText = (param) => (e) => {
    this.setState({
      [param]: e.target.value
    });
  }

  _handleCompFilter = (event) => {
    const val = parseInt(event.target.name);
    if (event.target.checked) {
      this.props.addFilter('comply', val);
    } else {
      this.props.removeFilter('comply', val);
    }
  }

  _handleRiskFilter = (event) => {
    const val = parseInt(event.target.name);
    if (event.target.checked) {
      this.props.addFilter('risk', val);
    } else {
      this.props.removeFilter('risk', val);
    }
  }

  componentWillUnmount() {
    this.props.resetFilter();
  }

  render() {
    const {riskFilter, complyFilter} = this.props;
    const {isHover, isDropdown} = this.state;
    let isActive = false;

    if (riskFilter.length > 0 || complyFilter.length > 0) {
      isActive = true;
    }

    const icon = (isHover || isActive) ? "/assets/reports/ic_12px_filter_hover.svg" : "/assets/reports/ic_12px_filter_default.svg";

    return (
      <div className={classNames("filter")}>
                <span
                  onClick={this._updateState("isDropdown", !this.state.isDropdown)}
                  onMouseEnter={this._updateState("isHover", true)}
                  onMouseLeave={this._updateState("isHover", false)}>
                  {!isActive
                    ? <img alt="Filter icon" src={icon}/>
                    : <FontAwesomeIcon icon={faFilter} className="search--icon" style={{fontSize:16}}/>
                  } Filter

                </span>
        <span className={classNames("filter--list")}>

                </span>
        {
          isDropdown &&
          <div>
            <div className="backdrop" onClick={this._updateState("isDropdown", !this.state.isDropdown)}></div>
            <div className={"checkbox--list"}>

              <FontAwesomeIcon icon={faSearch} className="search--icon"/>
              <input
                type="text" value={this.state.filterBy}
                onChange={this._updateText("filterBy")}
                placeholder={this.props.translate("reports.filterBy")}/>

              <div className="divider"></div>


              <div className={"list--container"}>
                <div className="item title">
                  Compliance
                </div>
                {complianceOptions.map(opt => (
                  <div className="item" key={opt.text}><label><input type="checkbox" name={opt.value}
                                                                     onChange={this._handleCompFilter}
                                                                     checked={complyFilter.indexOf(opt.value) !== -1}/>{opt.text}
                  </label>
                  </div>
                ))}

                <div className="item title">
                  Risk
                </div>
                {riskOptions.map(opt => (
                  <div className="item" key={opt.text}><label><input type="checkbox" name={opt.value}
                                                                     onChange={this._handleRiskFilter}
                                                                     checked={riskFilter.indexOf(opt.value) !== -1}/>{opt.text}
                  </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        }
      </div>
    );
  }

}

Filter.propTypes = {
  addFilter:    PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
  riskFilter:   PropTypes.array.isRequired,
  complyFilter: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  return {
    riskFilter:   state.report.cardListFilter.risk,
    complyFilter: state.report.cardListFilter.comply,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    addFilter(type, value) {
      dispatch(reportActions.addCardListFilter({type, value}));
    },
    removeFilter(type, value) {
      dispatch(reportActions.removeCardListFilter({type, value}));
    },
    resetFilter() {
      dispatch(reportActions.resetCardListFilter({}));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(Filter));
