/**
 * Created by Constantine Kuks on 18/05/2021.
 */

export const ROLE_USER = 'user';
export const ROLE_ADMIN = 'admin';
export const ROLE_CLIENT = 'client';

const  USER_ROLES = [
  ROLE_USER,
  ROLE_ADMIN,
  ROLE_CLIENT
];

export default USER_ROLES;