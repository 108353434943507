import React from 'react';
import {Route} from "react-router";

const CommonRoutes = props => {
  return (
    <>
      <Route path='/reports'>
      </Route>
    </>
  );
};



export default CommonRoutes;
