import axios from "axios";
import {reportsBaseActions} from "../reducers/reports";
import uiActions from "./uiActions";
import authActions from "./authActions";
import _ from "underscore";

export const createReport = (data) => {
  return (dispatch) => {
    dispatch(uiActions.setIsCreatingReport(true));
    axios.post(`/api/report/report`, {...data}, {withCredentials: true})
      .then((response) => {
        dispatch(reportsBaseActions.addReportToFullList(response.data.report));
        dispatch(uiActions.setReportCreationState('success'));
        dispatch(uiActions.setSuccess({message: "Report created successfully"}));
        dispatch(uiActions.setIsCreatingReport(false));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401){
          dispatch(authActions.logout());
        }
        dispatch(uiActions.setReportCreationState('error'));
        dispatch(uiActions.setError({message: "Failed to create report\n" + err.response.data.message}));
        dispatch(uiActions.setReportCreationState('fail'));
        dispatch(uiActions.setIsCreatingReport(false));
      });
  };
};

export const importReport = (data) => {
  return (dispatch) => {
    dispatch(uiActions.setIsCreatingReport(true));
    axios.post(`/api/report/report/import`, {...data}, {withCredentials: true})
      .then((response) => {
        dispatch(reportsBaseActions.addReportToFullList(response.data));
        dispatch(uiActions.setReportCreationState('success'));
        dispatch(uiActions.setSuccess({message: "Report imported successfully"}));
        dispatch(uiActions.setIsCreatingReport(false));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401){
          dispatch(authActions.logout());
        }
        dispatch(uiActions.setReportCreationState('error'));
        dispatch(uiActions.setError({message: "Failed to create report\n" + err.response.data.message}));
        dispatch(uiActions.setReportCreationState('fail'));
        dispatch(uiActions.setIsCreatingReport(false));
      });
  };
};

export const saveReport = (data, reportId) => {
  return (dispatch) => {
    dispatch(uiActions.setIsCreatingReport(true));
    axios.put(`/api/report/report/${reportId}`, {...data}, {withCredentials: true})
      .then((response) => {
        dispatch(reportsBaseActions.updateReportInFullList(response.data.report));
        dispatch(uiActions.setReportCreationState('success'));
        dispatch(uiActions.setSuccess({message: "Report edited successfully"}));
        dispatch(uiActions.setIsCreatingReport(false));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401){
          dispatch(authActions.logout());
        }
        dispatch(uiActions.setReportCreationState('fail'));
        dispatch(uiActions.setError({message:"Failed to save report"}));
        dispatch(uiActions.setIsCreatingReport(false));
      });
  };
};

export const deleteReport = (uuid) => {
  return (dispatch) => {
    axios.delete(`/api/report/report/${uuid}`, {withCredentials: true})
      .then((response) => {
        dispatch(reportsBaseActions.removeReportFromList(response.data.report.uuid));
        dispatch(uiActions.setSuccess({message:"Report deleted successfully"}));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401){
          dispatch(authActions.logout());
        }
        dispatch(uiActions.setError({message:"Failed to delete report"}));
      })
  };
};
export const setReportPublished = (uuid, newValue) => {
  return (dispatch) => {
    axios.patch(`/api/report/report/${uuid}`, {isPublished: newValue},{withCredentials: true})
      .then((response) => {
        dispatch(reportsBaseActions.updateReportList(_.pick(response.data.report,'uuid', 'createdAt', 'customer', 'dateRange', 'isPublished')));
        dispatch(uiActions.setSuccess({message:"Report publish state changed successfully"}));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401){
          dispatch(authActions.logout());
        }
        dispatch(uiActions.setError({message:"Failed to change publish state of report"}));
      })
  };
};

export const getReports = () => {
  return (dispatch) => {
    dispatch(reportsBaseActions.setIsFetched(false));
    axios.get(`/api/report/reports`, {withCredentials: true})
      .then((response) => {
        dispatch(reportsBaseActions.setFullReports(response.data.reports));
      })
      .catch((err) => {
        dispatch(uiActions.setError({message:"Failed to fetch reports"}));
        if (err.response && err.response.status === 401){
          dispatch(authActions.logout());
        }
      }).finally(() => {
      dispatch(reportsBaseActions.setIsFetched(true));
    });
  };
};

export const getReport = (uuid) => {
  return (dispatch) => {
    dispatch(reportsBaseActions.setIsFetched(false));
    axios.get(`/api/report/report/` + uuid, {withCredentials: true})
      .then((response) => {
        dispatch(reportsBaseActions.addReportToFullList(response.data.report));
      })
      .catch((err) => {
        dispatch(uiActions.setError({message:"Failed to fetch report.\n Invalid ID"}));
        // if (err.response && err.response.status === 401){
        //   dispatch(authActions.logout());
        // }
      }).finally(() => {
      dispatch(reportsBaseActions.setIsFetched(true));
    });
  };
};

export const getReportsList = () => {
  return (dispatch) => {
    dispatch(reportsBaseActions.setIsFetched(false));
    axios.get(`/api/report/reportsList`, {withCredentials: true})
      .then((response) => {
        dispatch(reportsBaseActions.setReportList(response.data.reports));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401){
          dispatch(authActions.logout());
        }
        dispatch(uiActions.setError({message:"Failed to fetch reports"}));
      }).finally(() => {
      dispatch(reportsBaseActions.setIsFetched(true));
    });
  };
};


const allAuthActions = {
  ...reportsBaseActions,
  createReport,
  importReport,
  deleteReport,
  setReportPublished,
  getReports,
  getReportsList,
  getReport,
  saveReport
};


export default allAuthActions;
