import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import IndexComponent from "./IndexComponent";
import axios from 'axios';


if (process.env.NODE_ENV === 'development')
axios.defaults.baseURL =  "http://localhost";

ReactDOM.render(
  <React.StrictMode>
    <IndexComponent/>
  </React.StrictMode>
  , document.getElementById('root'));


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
