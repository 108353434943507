import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import './SecurityTableRow.scss';
import SimpleTextInput from "./SimpleTextInput";
import ComplyDropdown from "./ComplyDropdown";
import RiskDropdown from "./RiskDropdown";
import _ from "underscore";

const riskCompImpact = [
  [0, 12.5, 18.75, 25],
  [-8.3, 25, 37.5, 50],
  [-16.6, 37.5, 56.25, 75],
  [-24.9, 50, 75, 100],
];

const weight = (type) => {
  switch (type) {
    case 'Operational':
      return 20;
    case 'Readiness':
      return 5.9;
    case 'Visibility':
      return 11.111111111111; // 9 items
      // return 10; // 10 items
      // return 9.090909090; // 11 items
    default:
      return 0;
  }
};


const SecurityTableRow = props => {
  const prevProps = useRef(props);

  const [solution, setSolution] = useState(props.solution);
  const [note, setNote] = useState(props.note);
  const [comply, setComply] = useState(props.compliance);
  const [risk, setRisk] = useState(props.risk);
  const [override, setOverride] = useState(false);
  const impact = ((riskCompImpact[risk][comply] / riskCompImpact[risk][3]) * weight(props.type));

  useEffect(() => {
    if ( !_.isEqual(prevProps.current, props) && !override && props.reportId){
      setSolution(props.solution);
      setNote(props.note);
      setComply(props.compliance);
      setRisk(props.risk);
      setOverride(true);
    }
    prevProps.current = props;
  }, [override, props, solution]);

  return (
    <tr>
      <td className={"topic"}>{props.topic}
        <input name={`${props.type.toLocaleLowerCase()}.${props.rowIndex}.topic`}
               defaultValue={props.topic} style={{display: "none"}}/></td>
      <td className={"description"}>{props.description}
        <input name={`${props.type.toLocaleLowerCase()}.${props.rowIndex}.description`}
               defaultValue={props.description} style={{display: "none"}}/></td>
      <td className={"solution"}><SimpleTextInput onChange={setSolution}
                                                  name={`${props.type.toLocaleLowerCase()}.${props.rowIndex}.solution`}
                                                  value={solution}  placeholder={"Add a solution..."}/></td>
      <td className={"note"}><SimpleTextInput onChange={setNote}
                                              name={`${props.type.toLocaleLowerCase()}.${props.rowIndex}.note`}
                                              value={note}  placeholder={"Add a note..."}/></td>
      <td className={"compliance"}><ComplyDropdown onChange={setComply} value={parseInt(comply)}
                                                   name={`${props.type.toLocaleLowerCase()}.${props.rowIndex}.comply`}/>
      </td>
      <td className={"risk"}><RiskDropdown onChange={setRisk} value={parseInt(risk)}
                                           name={`${props.type.toLocaleLowerCase()}.${props.rowIndex}.risk`}/></td>
      <td className={"impact"}>{impact.toFixed(1)}</td>
    </tr>
  );
};

SecurityTableRow.propTypes = {
  topic:       PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  risk:        PropTypes.number,
  compliance:  PropTypes.number,
  solution:    PropTypes.string,
  note:        PropTypes.string,
  type:        PropTypes.oneOf(['Operational', 'Readiness', 'Visibility']).isRequired,
  rowIndex:    PropTypes.number.isRequired,
};

SecurityTableRow.defaultProps = {
  risk:        2,
  compliance:  3,
  solution:    "",
  note:        "",
}

export default SecurityTableRow;
