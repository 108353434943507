import React, {useEffect} from 'react';
import styles from './UserChangePasswordForm.module.scss';
import TextInput from "../../common/TextInput";
import {useDispatch, useSelector} from "react-redux";
import userActions from "../../../store/actions/userActions";
import uiActions from "../../../store/actions/uiActions";
import {useHistory} from "react-router";
import {toast} from "react-toastify";
import PropTypes from "prop-types";

const UserChangePasswordForm = (props) => {

  const dispatch = useDispatch();
  const {user: userUIState} = useSelector(state => state.ui);
  const history = useHistory();


  useEffect(() => {
    if (userUIState.changePasswordState === 'success') {
      dispatch(uiActions.setChangePasswordState(undefined));
      if (props.onClose){
        props.onClose();
      }
    }
    return () => {
    };
  }, [userUIState, dispatch, history, props]);


  const handleUserSubmit = (event) => {
    event.preventDefault();
    const password = event.target.password.value;
    const newPassword = event.target.newPassword.value;
    const newPasswordRepeat = event.target.newPasswordRepeat.value;

    const details = { password, newPassword, newPasswordRepeat};

    if ( newPassword !== newPasswordRepeat){
      return toast.error("Passwords do not match");
    }

    dispatch(userActions.changePassword(details));
  }


  return (
    <div className={styles.wrapper}>
      <form onSubmit={handleUserSubmit} className={styles.form}>
        <TextInput name={"Password"} id={'password'} type={"password"} isRequired disabled={userUIState.isChangingPassword}/>
        <TextInput name={"New password"} id={'newPassword'} type={"password"} isRequired disabled={userUIState.isChangingPassword} min={8}/>
        <TextInput name={"Repeat new password"} id={'newPasswordRepeat'} type={"password"} isRequired disabled={userUIState.isChangingPassword} min={8}/>

        <button disabled={userUIState.isCreatingUser}>Submit</button>
      </form>
    </div>
  );
};

UserChangePasswordForm.propTypes = {
  onClose: PropTypes.func
}

export default UserChangePasswordForm;
