import {createSlice} from "@reduxjs/toolkit";

const initialAuthState ={
  list: [],
  isFetched: false
};


const userSlice = createSlice({
  name:'user',
  initialState: initialAuthState,
  reducers:{
    addUserToList(state, action){
      state.list.push(action.payload);
    },
    removeUserFromList(state, action){
      state.list = state.list.filter(cust => cust.uuid !== action.payload);
    },
    setUserList(state, action){
      state.list = action.payload;
    },
    setIsFetched(state, action){
      state.isFetched = action.payload;
    },
    updateUserList(state, action){
      state.list = state.list.map(user => user.uuid === action.payload.uuid ? action.payload : user);
    }
  }

});

export const userBaseActions = userSlice.actions;
export default userSlice.reducer;