import React from 'react';
import PropTypes from 'prop-types';
import StatisticsTableRow from "./StatisticsTableRow";
import styles from "./StatisticsTable.module.scss";

const StatisticsTable = props => {

  return (
    <table className={styles.table}>
      <tbody>
        {props.rows.map((row, index) => (
          <StatisticsTableRow key={row.id} id={row.id} topic={row.topic} type={row.type} component={row.component}/>
        ))}
      </tbody>
    </table>
  );
};

StatisticsTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({
    topic: PropTypes.string.isRequired,
    id:    PropTypes.string.isRequired,
    type:  PropTypes.string.isRequired,
    component: PropTypes.object,
  })).isRequired,
};

export default StatisticsTable;
