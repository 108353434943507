import React, {useEffect, useState} from 'react';
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import styles from "../ReportsForm.module.scss";
import StatisticsTable from "./Components/StatisticsTable";
import _ from "underscore";
import {toast} from "react-toastify";
import PropTypes from "prop-types";

const initPerCategories = [
  [{
    value:         "Category",
    disableEvents: true,
    readOnly: true,
    className:     styles["cell-style__header"]
  }, {
    value:         "Count",
    disableEvents: true,
    readOnly: true,
    className:     styles["cell-style__header"]
  }],
  [{value: ""}, {value: ""}],
  [{value: ""}, {value: ""}]
]
const initPerSeverities = [
  [{
    value:         "Severity",
    disableEvents: true,
    readOnly: true,
    className:     styles["cell-style__header"]
  }, {
    value:         "Count",
    disableEvents: true,
    readOnly: true,
    className:     styles["cell-style__header"]
  }],
  [{value: "Critical", disableEvents: true, readOnly: true}, {value: ""}],
  [{value: "High", disableEvents: true, readOnly: true}, {value: ""}],
  [{value: "Medium", disableEvents: true, readOnly: true}, {value: ""}],
  [{value: "Low", disableEvents: true, readOnly: true}, {value: ""}],
  [{value: "Info", disableEvents: true, readOnly: true}, {value: ""}],
];

const getPerSeverityFromObject = (obj) => {
  return [
    [{
      value:         "Severity",
      disableEvents: true,
      readOnly: true,
      className:     styles["cell-style__header"]
    }, {
      value:         "Count",
      disableEvents: true,
      readOnly: true,
      className:     styles["cell-style__header"]
    }],
    [{value: "Critical", disableEvents: true, readOnly: true}, {value: obj.critical}],
    [{value: "High", disableEvents: true, readOnly: true}, {value: obj.high}],
    [{value: "Medium", disableEvents: true, readOnly: true}, {value: obj.medium}],
    [{value: "Low", disableEvents: true, readOnly: true}, {value: obj.low}],
    [{value: "Info", disableEvents: true, readOnly: true}, {value: obj.info}],
  ]
}

const StatisticsSection = (props) => {

  const [perCategories, setPerCategories] = useState(props.perCategories ? [initPerCategories[0], ...props.perCategories.map(p => [{value: p.name}, {value: p.value}])] : initPerCategories);
  const [perSeverities, setPerSeverities] = useState(props.incidentsPerSeverity ? [initPerSeverities[0], ...getPerSeverityFromObject(props.incidentsPerSeverity)] : initPerSeverities);
  const [perSeveritiesUnresolved, setPerSeveritiesUnresolved] = useState(props.unresolvedPerSeverity ? [initPerSeverities[0], ...getPerSeverityFromObject(props.unresolvedPerSeverity)] : initPerSeverities);

  const [numberOfRows, setNumberOfRows] = useState(2);
  const [firstRun, setFirstRun] = useState(true);


  useEffect(() => {
    if ( !props.statistics ) return;

    if (perCategories.length - 1 < props.statistics.perCategory.length) {
      let newPerCategory = perCategories.slice(0,1);
      newPerCategory = newPerCategory.concat(props.statistics.perCategory.map(m => ([{value: m.name}, {value: m.value}])));
      setPerCategories(newPerCategory);
    }

    let newPerSeverities = perSeverities.map(m=> {
      if (m[0].value.toLowerCase() !== "severity") {
        return [m[0],{
          value: parseInt(props.statistics.incidentsPerSeverity[m[0].value.toLowerCase()])
        }]
      }
      return m;
    });
    setPerSeverities(newPerSeverities);


    let newPerSeveritiesUnresolved = perSeveritiesUnresolved.map(m=> {
      if (m[0].value.toLowerCase() !== "severity") {
        return [m[0],{
          value: parseInt(props.statistics.unresolvedPerSeverity[m[0].value.toLowerCase()])
        }]
      }
      return m;
    });
    setPerSeveritiesUnresolved(newPerSeveritiesUnresolved);

    // incidentsPerSeverity
    // perCategory
    // unresolvedPerSeverity
    // eslint-disable-next-line
  },[props.statistics]);


  useEffect(() => {
    if (firstRun) {
      setFirstRun(false);
      return;
    }
    let newArray = perCategories.map(row => [...row]);
    if (numberOfRows > perCategories.length - 1) {

      for (let i = 0; i < numberOfRows - (perCategories.length - 1); i++) {
        newArray.push([{value: ""}, {value: ""}]);
      }
    } else {
      newArray = newArray.slice(0, numberOfRows + 1);
    }
    setPerCategories(newArray);
    // eslint-disable-next-line
  }, [numberOfRows]);

  useEffect(() => {
    props.onChange(perCategories, perSeverities, perSeveritiesUnresolved);
    // eslint-disable-next-line
  }, [perCategories, perSeverities, perSeveritiesUnresolved]);

  const handleRowsChange = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setNumberOfRows(parseInt(event.target.value))
    }
  }

  const questionList = [
    {
      topic: "Total events collected",
      id:    "total",
      type:  "number",
    }, {
      topic:     "Events per category",
      id:        "perCategory",
      type:      "number",
      component: (<div><ReactDataSheet
        data={perCategories}
        className={styles["form-group__input-table"]}
        valueRenderer={cell => cell.value}
        onCellsChanged={changes => {
          const newGrid = perCategories.map(row => [...row]);
          changes.forEach(({cell, row, col, value}) => {
            if (col === 1 && _.isNaN(parseInt(value)) && !_.isEmpty(value)) {
              toast.error("Only numbers");
              return;
            }
            newGrid[row][col] = {...perCategories[row][col], value};
          });

          if (!_.isEmpty(newGrid[newGrid.length - 1][0].value)) {
            newGrid.push([{value: ""}, {value: ""}]);
          }

          setPerCategories(newGrid);
        }}
      /><label>Number of rows: </label><input type="number" defaultValue={2} onKeyDown={handleRowsChange}/></div>)
    }, {
      topic:     "Events per severity",
      id:        "incidentsPerSeverity",
      type:      "number",
      component: (<ReactDataSheet
        data={perSeverities}
        className={styles["form-group__input-table"]}
        valueRenderer={cell => cell.value}
        onCellsChanged={changes => {
          const newGrid = perSeverities.map(row => [...row]);
          changes.forEach(({cell, row, col, value}) => {
            if (col === 1 && _.isNaN(parseInt(value)) && !_.isEmpty(value)) {
              toast.error("Only numbers");
              return;
            }
            newGrid[row][col] = {...perSeverities[row][col], value};
          });
          setPerSeverities(newGrid);
        }}
      />)
    }, {
      topic: "Mean Time To Detect And Resolve",
      id:    "ttdr",
      type:  "number",
    }, {
      topic:     "Unresolved per severity",
      id:        "unresolvedPerSeverity",
      type:      "number",
      component: (<ReactDataSheet
        data={perSeveritiesUnresolved}
        className={styles["form-group__input-table"]}
        valueRenderer={cell => cell.value}
        onCellsChanged={changes => {
          const newGrid = perSeveritiesUnresolved.map(row => [...row]);
          changes.forEach(({cell, row, col, value}) => {
            if (col === 1 && _.isNaN(parseInt(value)) && !_.isEmpty(value)) {
              toast.error("Only numbers");
              return;
            }
            newGrid[row][col] = {...perSeveritiesUnresolved[row][col], value};
          });
          setPerSeveritiesUnresolved(newGrid);
        }}
      />)
    }, {
      topic: "Mean Time To Detect",
      id:    "mttd",
      type:  "number",
    }, {
      topic: "Mean Time to resolve",
      id:    "mttr",
      type:  "number",
    }, {
      topic: "Average Alerts per days ",
      id:    "avgIncPerWorkingDays",
      type:  "number",
    }, {
      topic: "Average Alerts per week",
      id:    "avgIncPerWorkingWeekend",
      type:  "number",
    }, {
      topic: "Total Log Source Monitored/Total Log Sources Available",
      id:    "avgIncPerAnalyst",
      type:  "number",
    }, {
      topic: "Total analysts",
      id:    "numOfAnalysts",
      type:  "number",
    }, {
      topic: "Total endpoints monitored",
      id:    "totEndpointsMonitored",
      type:  "number",
    }, {
      topic: "Total use cases",
      id:    "totTypesOfMonitoring",
      type:  "number",
    }, {
      topic: "Number Of Attempted Attack / Month",
      id:    "totUseCases",
      type:  "number",
    }
  ];

  return (
    <>

      <StatisticsTable rows={questionList}/>

      {/*<div className={styles['form-group']}>*/}
      {/*  <label htmlFor={"total"}>Total events collected</label><br/>*/}
      {/*  <input id="total" type="number" name="total" required*/}
      {/*         className={styles['form-group__input']}/>*/}
      {/*</div>*/}


      {/*/!* Per categories table*!/*/}
      {/*<u><h3>Events per categories</h3></u>*/}
      {/*<ReactDataSheet*/}
      {/*  data={perCategories}*/}
      {/*  className={styles["form-group__input-table"]}*/}
      {/*  valueRenderer={cell => cell.value}*/}
      {/*  onCellsChanged={changes => {*/}
      {/*    const newGrid = perCategories.map(row => [...row]);*/}
      {/*    changes.forEach(({cell, row, col, value}) => {*/}
      {/*      newGrid[row][col] = {...perCategories[row][col], value};*/}
      {/*    });*/}
      {/*    setPerCategories(newGrid);*/}
      {/*  }}*/}
      {/*/>*/}
      {/*<br/>*/}

      {/*<u><h3>Events per severity</h3></u>*/}
      {/*/!* Per severity table*!/*/}
      {/*<ReactDataSheet*/}
      {/*  data={perSeverities}*/}
      {/*  className={styles["form-group__input-table"]}*/}
      {/*  valueRenderer={cell => cell.value}*/}
      {/*  onCellsChanged={changes => {*/}
      {/*    const newGrid = perSeverities.map(row => [...row]);*/}
      {/*    changes.forEach(({cell, row, col, value}) => {*/}
      {/*      newGrid[row][col] = {...perSeverities[row][col], value};*/}
      {/*    });*/}
      {/*    setPerSeverities(newGrid);*/}
      {/*  }}*/}
      {/*/>*/}

      {/*<div className={styles['form-group']}>*/}
      {/*  <label htmlFor={"ttdr"}>Time to detect and resolve </label><br/>*/}
      {/*  <input id="ttdr" type="number" name="ttdr" required*/}
      {/*         className={styles['form-group__input']}/>*/}
      {/*  <Hint hintText={"Gross average resolution time / 60"} position={"top"}/>*/}
      {/*</div>*/}

      {/*/!* Unresolved per severity table*!/*/}
      {/*<u><h3>Unresolved per severity</h3></u>*/}
      {/*<ReactDataSheet*/}
      {/*  data={perSeveritiesUnresolved}*/}
      {/*  className={styles["form-group__input-table"]}*/}
      {/*  valueRenderer={cell => cell.value}*/}
      {/*  onCellsChanged={changes => {*/}
      {/*    const newGrid = perSeveritiesUnresolved.map(row => [...row]);*/}
      {/*    changes.forEach(({cell, row, col, value}) => {*/}
      {/*      newGrid[row][col] = {...perSeveritiesUnresolved[row][col], value};*/}
      {/*    });*/}
      {/*    setPerSeveritiesUnresolved(newGrid);*/}
      {/*  }}*/}
      {/*/>*/}

      {/*<div className={styles['form-group']}>*/}
      {/*  <label htmlFor={"mttd"}>Average Time to Recognition </label><br/>*/}
      {/*  <input id="mttd" type="number" name="mttd" required*/}
      {/*         className={styles['form-group__input']}/>*/}
      {/*</div>*/}

      {/*<div className={styles['form-group']}>*/}
      {/*  <label htmlFor={"mttr"}>Average Time to resolve </label><br/>*/}
      {/*  <input id="mttr" type="number" name="mttr" required*/}
      {/*         className={styles['form-group__input']}/>*/}
      {/*</div>*/}

      {/*<div className={styles['form-group']}>*/}
      {/*  <label htmlFor={"avgIncPerWorkingDays"}>Average incidents per working days </label><br/>*/}
      {/*  <input id="avgIncPerWorkingDays" type="number" name="avgIncPerWorkingDays" required*/}
      {/*         className={styles['form-group__input']}/>*/}
      {/*</div>*/}


      {/*<div className={styles['form-group']}>*/}
      {/*  <label htmlFor={"avgIncPerWorkingWeekend"}>Average incidents per working weekend </label><br/>*/}
      {/*  <input id="avgIncPerWorkingWeekend" type="number" name="avgIncPerWorkingWeekend" required*/}
      {/*         className={styles['form-group__input']}/>*/}
      {/*</div>*/}

      {/*<div className={styles['form-group']}>*/}
      {/*  <label htmlFor={"avgIncPerAnalyst"}>Average incidents per analyst </label><br/>*/}
      {/*  <input id="avgIncPerAnalyst" type="number" name="avgIncPerAnalyst" required*/}
      {/*         className={styles['form-group__input']}/>*/}
      {/*</div>*/}

      {/*<div className={styles['form-group']}>*/}
      {/*  <label htmlFor={"numOfAnalysts"}>Total analysts </label><br/>*/}
      {/*  <input id="numOfAnalysts" type="number" name="numOfAnalysts" required*/}
      {/*         className={styles['form-group__input']}/>*/}
      {/*</div>*/}

      {/*<div className={styles['form-group']}>*/}
      {/*  <label htmlFor={"totEndpointsMonitored"}>Total endpoints monitored </label><br/>*/}
      {/*  <input id="totEndpointsMonitored" type="number" name="totEndpointsMonitored" required*/}
      {/*         className={styles['form-group__input']}/>*/}
      {/*</div>*/}

      {/*<div className={styles['form-group']}>*/}
      {/*  <label htmlFor={"totTypesOfMonitoring"}>Total types of monitoring </label><br/>*/}
      {/*  <input id="totTypesOfMonitoring" type="number" name="totTypesOfMonitoring" required*/}
      {/*         className={styles['form-group__input']}/>*/}
      {/*</div>*/}

      {/*<div className={styles['form-group']}>*/}
      {/*  <label htmlFor={"totUseCases"}>Total use cases </label><br/>*/}
      {/*  <input id="totUseCases" type="number" name="totUseCases" required*/}
      {/*         className={styles['form-group__input']}/>*/}
      {/*</div>*/}


    </>

  )

};

StatisticsSection.propTypes = {
  statistics: PropTypes.object,
};

export default StatisticsSection;
