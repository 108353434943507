import React from 'react';
import StatisticsSection from "./StatisticsSection";
import styles from "./StatisticsEvent.module.scss";
import StatisticItem from "./StatisticItem";

const MyComponent = props => {
  return (
    <StatisticsSection title={" "} childrenClassName={styles.wrapper}>
      <StatisticItem value={props.total} title={"Total events collected"}/>
      
      <StatisticItem value={""} title={""}/>
      <StatisticItem value={props.totTypesOfMonitoring} title={"Total use Cases"}/>
      <StatisticItem value={props.totUseCases} title={"Number of attempted attack / Month"}/>
    </StatisticsSection>
  );
};

MyComponent.propTypes = {

};

export default MyComponent;
