import React from 'react';
import styles from "./TextInput.module.scss";
import PropTypes from "prop-types";
import _ from "underscore";

const DropdownInput = (props) => {

  const handleOnChange = (event) => {
    if (props.onChange && _.isFunction(props.onChange)) {
      props.onChange(event.target.value);
    }
  }

  return (
    <div className={styles.control} style={{marginBottom: 0}}>
      {props.hasLabel && <label htmlFor={props.id}>{props.name}</label>}
      <select name={props.name.toLocaleLowerCase()} defaultValue={props.defaultValue} id={props.id}
              required={props.isRequired} disabled={props.disabled}
              onChange={handleOnChange} value={props.value }>
        {props.options.map((option, index) => (
          <option key={option.name + option.value + index} value={option.value}>{option.name}</option>
        ))}
      </select>
    </div>
  );
};

DropdownInput.propTypes = {
  options:      PropTypes.arrayOf(PropTypes.shape({
    name:  PropTypes.string,
    value: PropTypes.any,
  })).isRequired,
  name:         PropTypes.string.isRequired,
  id:           PropTypes.string,
  defaultValue: PropTypes.any,
  isRequired:   PropTypes.bool,
  disabled:     PropTypes.bool,
  onChange:     PropTypes.func,
  hasLabel:     PropTypes.bool,
  value:        PropTypes.any.isRequired
}

DropdownInput.defaultProps = {
  hasLabel: true,
}

export default DropdownInput;
