import React from 'react';
import PropTypes from 'prop-types';
import styles from './Modal.module.scss';
import * as ReactDOM from "react-dom";
import classnames from 'classnames';

const Modal = props => {
  if (!props.isShown) return null;

  const handleClose = () => {
    props.onClose();
  }

  const modal = ReactDOM.createPortal(
    <>
      <div className={classnames(styles.backdrop)} onClick={handleClose}/>
      <div className={classnames(styles.modal, props.className)}>
        <button className={styles.close} onClick={handleClose}>X</button>
        {props.children}
      </div>
    </>
    , document.getElementById('modal-root'));

  return modal;
};

Modal.propTypes = {
  isShown:   PropTypes.bool.isRequired,
  onClose:   PropTypes.func.isRequired,
  className: PropTypes.string
};

export default Modal;
