import createStore from "./store";
import {Provider} from "react-redux";
import {LocalizeProvider} from "react-localize-redux";
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import React from "react";

const store = createStore();

export const IndexComponent = props => (
  <Provider store={store}>
    <LocalizeProvider>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </LocalizeProvider>
  </Provider>
);

export default IndexComponent;