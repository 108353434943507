import React from 'react';
import PropTypes from 'prop-types';
import styles from './StatisticsSection.module.scss';
import classnames from "classnames";

const StatisticsSection = props => {
  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <h2>{props.title}</h2>
        <hr/>
      </header>
      <section className={classnames(styles.main, props.childrenClassName)}>
        {props.children}
      </section>
    </div>
  );
};

StatisticsSection.propTypes = {
  title: PropTypes.string.isRequired,
  childrenClassName: PropTypes.string,
};

export default StatisticsSection;
