import React, {useEffect, useRef, useState} from 'react';
import classnames from "classnames";
import styles from "./ReportsForm.module.scss";
import {useHistory} from "react-router";
import {Translate} from "react-localize-redux";
import SecurityOperationSection from "./form/SecurityOperationSection";
import SecurityReadinessSection from "./form/SecurityReadinessSection";
import SecurityVisibilitySection from "./form/SecurityVisibilitySection";
import StatisticsSection from "./form/StatisticsSection";
import {useDispatch, useSelector} from "react-redux";
import reportActions from "../../store/actions/reportActions";
import PropTypes from "prop-types";
import customerActions from "../../store/actions/customerActions";
import {toast} from "react-toastify";
import WaitingOverlay from "../modals/WaitingOverlay";
import uiActions from "../../store/actions/uiActions";

const retrieveTableValues = (type, targetForm) => {
  let isValid = true;
  let i = 0;
  let newArray = [];
  while (isValid) {
    if (targetForm[`${type}.${i}.solution`]) {

      newArray.push({
        topic:       targetForm[`${type}.${i}.topic`].value,
        description: targetForm[`${type}.${i}.description`].value,
        solution:    targetForm[`${type}.${i}.solution`].value,
        note:        targetForm[`${type}.${i}.note`].value,
        compliance:  parseInt(targetForm[`${type}.${i}.comply`].value),
        risk:        parseInt(targetForm[`${type}.${i}.risk`].value),
      });

      i++;
    } else {
      isValid = false;
    }
  }

  return newArray;
}

// const setTableValues = (type, targetForm, valueArray) => {
//   let isValid = true;
//   let i = 0;
//   let newArray = [];
//   while (isValid) {
//     if (targetForm[`${type}.${i}.solution`]) {
//       let match = valueArray.find(row => row.topic === targetForm[`${type}.${i}.topic`].value);
//       if (match) {
//         // targetForm[`${type}.${i}.topic`].value = match.topic;
//         // targetForm[`${type}.${i}.description`].value = match.description;
//         targetForm[`${type}.${i}.solution`].value = match.solution;
//         targetForm[`${type}.${i}.note`].value = match.note;
//         targetForm[`${type}.${i}.comply`].value = match.compliance;
//         targetForm[`${type}.${i}.risk`].value = match.risk;
//       }
//
//       i++;
//     } else {
//       isValid = false;
//     }
//   }
//   return newArray;
// }

const retrieveSeverities = (grid) => {
  let severitiesObject = {};
  grid.forEach(row => {
    if (row[0].value !== "Severity") {
      severitiesObject[row[0].value.toLowerCase()] = parseInt(row[1].value || "0");
    }

  })
  return severitiesObject;
}

const retrieveCategory = (grid) => {
  let categoriesArray = [];
  grid.forEach(row => {
    if (row[0].value !== "Category") {
      categoriesArray.push({name: row[0].value, value: parseInt(row[1].value || "0")});
    }

  })
  return categoriesArray;
}

const ReportsForm = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const reportFormRef = useRef(null);

  const [statisticTables, setStatisticTables] = useState({});
  const [report, setReport] = useState(undefined);

  const customerState = useSelector(state => state.customer);
  const uiReportState = useSelector(state => state.ui.report);
  const fullReports = useSelector(state => state.report.fullReports);

  // On component mount
  useEffect(() => {
    dispatch(customerActions.getCustomers());
    if (props.reportId) {
      dispatch(reportActions.getReport(props.reportId));
    }

    // eslint-disable-next-line
  },[]);

  // When list of reports updates search for needed one
  useEffect(() => {
    const rep = fullReports.filter(report => report.uuid === props.reportId);
    if (rep[0] && !report && !customerState.isFetchingCustomer) {
      let report = rep[0];
      setReport(rep[0]);
      setTimeout(() => {
        reportFormRef.current.customerUuid.value = report.customerUuid;
        reportFormRef.current.dateRange.value = report.dateRange;
        reportFormRef.current.operationalRecommendation.value = report.secOpsRecommendation;
        reportFormRef.current.readinessRecommendation.value = report.secReadyRecommendation;
        reportFormRef.current.visibilityRecommendation.value = report.secVisRecommendation;
        reportFormRef.current.total.value = report.statistics.total;
        reportFormRef.current.ttdr.value = report.statistics.ttdr;
        reportFormRef.current.mttd.value = report.statistics.mttd;
        reportFormRef.current.mttr.value = report.statistics.mttr;
        reportFormRef.current.avgIncPerWorkingDays.value = report.statistics.avgIncPerWorkingDays
        reportFormRef.current.avgIncPerWorkingWeekend.value = report.statistics.avgIncPerWorkingWeekend;
        reportFormRef.current.avgIncPerAnalyst.value = report.statistics.avgIncPerAnalyst;
        reportFormRef.current.numOfAnalysts.value = report.statistics.numOfAnalysts;
        reportFormRef.current.totEndpointsMonitored.value = report.statistics.totEndpointsMonitored;
        reportFormRef.current.totTypesOfMonitoring.value = report.statistics.totTypesOfMonitoring;
        reportFormRef.current.totUseCases.value = report.statistics.totUseCases;
      },0);

    }
  }, [fullReports, props.reportId, report, customerState]);

  // Watch the UI state
  useEffect(() => {
    if (uiReportState.reportCreationState === 'success' && uiReportState.isCreatingReport === false){
      dispatch(uiActions.setReportCreationState(undefined));
      history.push('/reports');
    }
  }, [dispatch, history, uiReportState]);


  // useEffect(() => {
  //   if (report) {
  //     reportFormRef.current.customerUuid.value = report.customerUuid;
  //     reportFormRef.current.dateRange.value = report.dateRange;
  //     reportFormRef.current.operationalRecommendation.value = report.secOpsRecommendation;
  //     reportFormRef.current.readinessRecommendation.value = report.secReadyRecommendation;
  //     reportFormRef.current.visibilityRecommendation.value = report.secVisRecommendation;
  //     reportFormRef.current.total.value = report.statistics.total;
  //     reportFormRef.current.ttdr.value = report.statistics.ttdr;
  //     reportFormRef.current.mttd.value = report.statistics.mttd;
  //     reportFormRef.current.mttr.value = report.statistics.mttr;
  //     reportFormRef.current.avgIncPerWorkingDays.value = report.statistics.avgIncPerWorkingDays
  //     reportFormRef.current.avgIncPerWorkingWeekend.value = report.statistics.avgIncPerWorkingWeekend;
  //     reportFormRef.current.avgIncPerAnalyst.value = report.statistics.avgIncPerAnalyst;
  //     reportFormRef.current.numOfAnalysts.value = report.statistics.numOfAnalysts;
  //     reportFormRef.current.totEndpointsMonitored.value = report.statistics.totEndpointsMonitored;
  //     reportFormRef.current.totTypesOfMonitoring.value = report.statistics.totTypesOfMonitoring;
  //     reportFormRef.current.totUseCases.value = report.statistics.totUseCases;
  //   }
  // },[]);


  const handleBack = () => {
    history.push('/reports');
  }
  const handleSubmit = (event) => {
    event.preventDefault();

    const method = event.target.submitBtn ? "submit" : (event.target.saveBtn ? "save" : undefined);

    const submit = {
      customerUuid:           event.target.customerUuid.value,
      dateRange:              event.target.dateRange.value,
      secOps:                 retrieveTableValues('operational', event.target),
      secOpsRecommendation:   event.target.operationalRecommendation.value,
      secReady:               retrieveTableValues('readiness', event.target),
      secReadyRecommendation: event.target.readinessRecommendation.value,
      secVis:                 retrieveTableValues('visibility', event.target),
      secVisRecommendation:   event.target.visibilityRecommendation.value,
      statistics:             {
        total:                   parseInt(event.target.total.value),
        perCategory:             retrieveCategory(statisticTables.perCategories),
        incidentsPerSeverity:    retrieveSeverities(statisticTables.perSeverities),
        ttdr:                    parseFloat(event.target.ttdr.value),
        unresolvedPerSeverity:   retrieveSeverities(statisticTables.perSeveritiesUnresolved),
        mttd:                    parseFloat(event.target.mttd.value),
        mttr:                    parseFloat(event.target.mttr.value),
        avgIncPerWorkingDays:    parseInt(event.target.avgIncPerWorkingDays.value),
        avgIncPerWorkingWeekend: parseInt(event.target.avgIncPerWorkingWeekend.value),
        avgIncPerAnalyst:        parseInt(event.target.avgIncPerAnalyst.value),
        numOfAnalysts:           parseInt(event.target.numOfAnalysts.value),
        totEndpointsMonitored:   parseInt(event.target.totEndpointsMonitored.value),
        totTypesOfMonitoring:    parseInt(event.target.totTypesOfMonitoring.value),
        totUseCases:             parseInt(event.target.totUseCases.value),
      }
    };

    if (method === "submit"){
       dispatch(reportActions.createReport(submit));
    } else if ( method === "save") {
      dispatch(reportActions.saveReport(submit, props.reportId));
    } else {
      toast.error("Issues submitting the form, contact admin");
    }

  }

  const handleStatisticTablesChange = (perCategories, perSeverities, perSeveritiesUnresolved) => {
    setStatisticTables({perCategories, perSeverities, perSeveritiesUnresolved});
  };

  return (
    <div className={classnames(styles.wrapper)}>
      { uiReportState.isCreatingReport && <WaitingOverlay isShown/>}
      { customerState.isFetchingCustomer && <WaitingOverlay isShown/>}
      <button onClick={handleBack}><Translate id={"nav.back"}/></button>
      <form onSubmit={handleSubmit} ref={reportFormRef}>

        <div className={styles['form-group']}>
          <label htmlFor={"dateRange"}>What month is this report for:</label><br/>
          <input id="dateRange" type="month" name="dateRange"
                 className={styles['form-group__input']}/>
        </div>

        <div className={styles['form-group']}>
          <label htmlFor={"customerUuid"}>Which customer is this report for:</label><br/>
          <select id="customerUuid" name="customerUuid"  defaultValue={props.customer || 0}
                  className={styles['form-group__input']}>
            <option value={0}>Please select customer</option>
            {customerState.list.map(cust => (<option key={cust.uuid} value={cust.uuid}>{cust.name}</option>))}
          </select>
        </div>


        <u><h2>Operations</h2></u>
        <SecurityOperationSection rows={report ? report.secOps : undefined} reportId={props.reportId}/>
        <div className={styles['form-group']}>
          <label htmlFor={"operationalRecommendation"}>Recommendation</label>
          <textarea id="operationalRecommendation" name="operationalRecommendation"
                    className={styles['recommendation__textarea']}/>
        </div>

        <u><h2>Readiness</h2></u>
        <SecurityReadinessSection rows={report ? report.secReady : undefined} reportId={props.reportId}/>
        <div className={styles['form-group']}>

          <label htmlFor={"readinessRecommendation"}>Recommendation</label>
          <textarea id="readinessRecommendation" name="readinessRecommendation"
                    className={styles['recommendation__textarea']}/>
        </div>

        <u><h2>Visibility</h2></u>
        <SecurityVisibilitySection rows={report ? report.secVis : undefined} reportId={props.reportId}/>
        <div className={styles['form-group']}>
          <label htmlFor={"visibilityRecommendation"}>Recommendation</label>
          <textarea id="visibilityRecommendation" name="visibilityRecommendation"
                    className={styles['recommendation__textarea']}/>
        </div>
        <br/>

        <u><h2>Statistics</h2></u>

        <StatisticsSection onChange={handleStatisticTablesChange} statistics={report ? report.statistics : undefined}/>

        {!props.reportId && <button type="submit" name={"submitBtn"}>Submit</button>}
        {props.reportId && <button type="submit" name={"saveBtn"}>Save</button>}
      </form>
    </div>
  );
};

ReportsForm.propTypes = {
  customer: PropTypes.string,
  reportId: PropTypes.string,
}

export default ReportsForm;
