import {createSlice} from "@reduxjs/toolkit";

const initialUIState = {
  isShownLoginModal: false,
  successErrorType:  undefined,
  error:             undefined,
  success:           undefined,
  reportEditDone:    false,
  customer:          {
    isCreatingCustomer:    false,
    customerCreationState: undefined,
    isFetchingCustomer:    false,
  },
  user:              {
    isCreatingUser:    false,
    isChangingPassword:    false,
    userCreationState: undefined,
    changePasswordState: undefined,
  },
  report:            {
    isCreatingReport:    false,
    reportCreationState: undefined,
  }
};

const customerUiActions = {
  setIsCreatingCustomer(state, action) {
    state.customer.isCreatingCustomer = action.payload;
  },
  setCustomerCreationState(state, action) {
    state.customer.customerCreationState = action.payload;
    state.customer.isCreatingCustomer = false;
  },
  setCustomerFetchState(state, action) {
    state.customer.isFetchingCustomer = action.payload;
  }
};

const userUiActions = {
  setIsCreatingUser(state, action) {
    state.user.isCreatingUser = action.payload;
  },
  setIsChangingPassword(state, action) {
    state.user.isChangingPassword = action.payload;
  },
  setUserCreationState(state, action) {
    state.user.userCreationState = action.payload;
    state.user.isCreatingUser = false;
  },
  setChangePasswordState(state, action) {
    state.user.changePasswordState = action.payload;
    state.user.isChangingPassword = false;
  }
};

const reportUiActions = {
  setIsCreatingReport(state, action) {
    state.report.isCreatingReport = action.payload;
  },
  setReportCreationState(state, action) {
    state.report.reportCreationState = action.payload;
    state.report.isCreatingReport = false;
  }
};


const uiSlice = createSlice({
  name:         'UI',
  initialState: initialUIState,
  reducers:     {
    switchLoginModal(state) {
      state.isShownLoginModal = !state.isShownLoginModal;
    },
    setError(state, action) {
      state.error = action.payload.message;
    },
    setSuccess(state, action) {
      state.success = action.payload.message;
    },
    ...customerUiActions,
    ...userUiActions,
    ...reportUiActions
  }

});

export const uiBaseActions = uiSlice.actions;
export default uiSlice.reducer;