import React from 'react';
import PropTypes from 'prop-types';
import './Hint.scss';
import classNames from "classnames";

export const Hint = props => {
    return (
        <div className="hint">
            <img src="/assets/reports/ic_12px_info.svg" alt="hit icon"/>
            <p className={classNames("hint--text", {[props.position]: true})}>{props.hintText}</p>
        </div>
    );
};

Hint.propTypes = {
    hintText: PropTypes.element.isRequired,
    position: PropTypes.oneOf(['top','right','left','bottom']).isRequired,
};

export default Hint;