import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretUp, faCaretDown} from "@fortawesome/free-solid-svg-icons";
import './Score.scss'
import classNames from "classnames";
import { LineChart, Line} from 'recharts';

export function Score(props) {

    let data = props.graphData.map(d => {
        return {
            name: 'point',
            uv: d
        }
    });


    return (
        <div className="score">
            <div className="title">Score</div>
            <div className="score--value">
                {props.value}
                <FontAwesomeIcon
                    className={classNames("chevron", {up: props.isInc, down: !props.isInc})}
                    icon={(props.isInc ? faCaretUp : faCaretDown)}/>
            </div>

                <LineChart
                    width={192}
                    height={44}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="1" y2="0">
                            <stop offset="0%" stopColor="#092247" stopOpacity={0.1}/>
                            <stop offset="100%" stopColor="#092247" stopOpacity={1}/>
                        </linearGradient>
                    </defs>
                    <Line dataKey="uv" stroke="url(#colorUv)" strokeWidth={2} dot={false}/>
                </LineChart>

        </div>
    );
}

Score.propTypes = {
    value: PropTypes.string.isRequired,
    graphData: PropTypes.arrayOf(PropTypes.number).isRequired,
    isInc: PropTypes.bool.isRequired,
};

Score.defaultProps = {
    graphData: []
}

export default Score;