import {createSlice} from "@reduxjs/toolkit";

const initialCustomerState ={
  list: [],
  isFetched: false
};


const customerSlice = createSlice({
  name:'customer',
  initialState: initialCustomerState,
  reducers:{
    addCustomerToList(state, action){
      state.list.push(action.payload);
    },
    removeCustomerFromList(state, action){
      state.list = state.list.filter(customer => customer.uuid !== action.payload);
    },
    setCustomerList(state, action){
      state.list = action.payload;
    },
    updateCustomerList(state, action){
      state.list = state.list.map(customer => customer.uuid === action.payload.uuid ? action.payload : customer);
    },
    setIsFetched(state, action){
      state.isFetched = action.payload;
    }
  }

});

export const customerBaseActions = customerSlice.actions;
export default customerSlice.reducer;